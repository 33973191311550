import "moment/locale/es";
import "moment-timezone";

import { Box, Button, Checkbox, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircularProgress from "@mui/material/CircularProgress";
import Countdown from "react-countdown";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListDays from "../ListDays";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListUsersReservation from "../ListUsersReservation";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Terminosycondiciones from "../Terminosycondiciones";
import Tooltip from "@mui/material/Tooltip";
import addDays from "../../utils/adddays";
import axios from "axios";
import esLocale from "date-fns/locale/es";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import useLocalTime from "../../hooks/useLocalTime";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useMounted from "../../hooks/useMounted";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#f4f9fb",
    color: theme.palette.text.primary,
    border: "none",
    boxShadow: "none",
  },
  heading: {
    flexShrink: 0,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  details: {
    border: "none",
    boxShadow: "none",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "16px",
  width: {
    md: 370,
    sm: 370,
    xs: "90%",
  },
  p: 4,
};

const PicklballSportsForm = ({ typeId = 8, props }) => {
  const localTime = useLocalTime();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = ["Días disponibles", "Horario", "Reservar"];
  const mounted = useMounted();

  const { isAuthenticated, userInfo, friends } = useSelector((state) => state.userLogin);

  const { restInitialized, type } = useSelector((state) => state.type);
  const [formType, setFormType] = useState(typeId);
  const [daysoff, setDaysoff] = useState([]);
  const [resHours, setResHours] = useState([]);
  const [arrivalDate, setArrivalDate] = useState();
  const [exitEnable, setExitEnable] = useState(true);
  const [optionEnable, setOptionEnable] = useState(true);
  const [arrivalEnable, setArrivalEnable] = useState(true);
  const [exitHours, setExitHours] = useState([]);
  const [gameOptions, setGameOptions] = useState([]);
  const [gameTypeOptions, setGameTypeOptions] = useState([]);
  const [insideGameActive, setInsideGameActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [submitErrors, setSubmitErrors] = useState();
  const [serverState, setServerState] = useState();
  const [availability, setAvailability] = useState(false);
  const [availabilityStatus, setAvailabilityStatus] = useState(false);
  const [listHoures, setListHoures] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [daysLoopForm, setDaysLoopForm] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [availabilityCheck, setAvailabilityCheck] = useState(false);
  const [listHourestype, setListHouresType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [open2, setOpen2] = useState(false);
  const [filterFriends, setFilterFriends] = useState([]);

  let infores = type.find((page) => page.id === formType);

  const [currentType, setCurrentType] = useState(infores);

  const [maxDate, setMaxDate] = useState(addDays(moment().tz("America/Panama").format(), currentType.max_days));

  const [minDate, setMinDate] = useState(addDays(moment().tz("America/Panama").format(), currentType.min_days));

  const classes = useStyles();
  const descriptionElementRef = React.useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [reservedInfoOpen, setReservedInfoOpen] = useState(false);
  const [reservedInfoID, setReservedInfoID] = useState();
  const [reservedInfoOpenData, setReservedInfoOpenData] = useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };

  const handleCloseD = () => {
    setOpen2(false);
  };

  function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const generateHours = (date, schedule) => {
    const today = new Date();
    const is_Today = isToday(date);

    setResHours(["Cargando..."]);

    const selectDay = new Date(date);
    const day_numberq = new Date(date).getDay();
    let day_hour = new Date().getHours();

    /*let schedules = schedule ? schedule.type_shedule : currentType.type_shedule*/
    let schedules = currentType.type_shedule;

    let disable_hours = schedule.find((day) => day.day_number === String(day_numberq));

    const dHArray = disable_hours.disable_hours.split(",");

    let day_schedule = schedules.filter((day) => day.day_number === String(day_numberq));

    let open = Number(moment(day_schedule[0].open, "h:mm:ss").format("HH"));
    let last_schedule = Number(moment(day_schedule[0].last_schedule, "h:mm:ss").format("HH"));
    let closed = Number(moment(day_schedule[0].closed, "h:mm:ss").format("HH"));

    var startTimeinit = moment(today, "HH:mm:ss a");
    var endTimeinit = moment(day_schedule[0].last_schedule, "HH:mm:ss a");

    var difinit = moment.duration(endTimeinit.diff(startTimeinit));
    var minutesinit = difinit.hours() * 60 + difinit.minutes();

    if (minutesinit < 60 && day_hour <= 23 && is_Today == true) {
      setArrivalDate(today.setDate(today.getDate() + 1));
    }

    const hours = [];
    const exitHours = [];

    if (last_schedule === 0) {
      last_schedule = 24;
    }

    for (let hour = open; hour <= last_schedule; hour++) {
      const isInArray = dHArray.includes(String(hour));

      if (selectDay.getDate() === today.getDate()) {
        var startTime = moment(today, "HH:mm:ss a");
        var endTime = moment(hour, "HH:mm:ss a");
        var endTimeb = moment(hour, "HH:mm:ss a").add(30, "minutes");

        var dif = moment.duration(endTime.diff(startTime));
        var minutes = dif.hours() * 60 + dif.minutes();

        var difb = moment.duration(endTimeb.diff(startTime));
        var minutesb = difb.hours() * 60 + difb.minutes();

        if (minutes > 59) {
          if (isInArray === false) {
            hours.push(moment({ hour }).format("h:mm a"));
          }
        }

        if (minutesb > 59) {
          if (hour !== last_schedule) {
            /* hours.push(moment({ hour, minute: 30 }).format('h:mm a')); */
          }
        }

        /*
        if (today.getHours() < open) {
          hours.push(moment({ hour }).format('h:mm a'));
          hours.push(moment({ hour, minute: 30 }).format('h:mm a'));
        }*/
      } else {
        if (isInArray === false) {
          hours.push(moment({ hour }).format("h:mm a"));
          if (hour !== last_schedule) {
            /*hours.push(moment({ hour, minute: 30 }).format('h:mm a'));*/
          }
        }
      }
    }

    setResHours(hours);
  };

  const generateHoursList = (data, date) => {

    console.log(data);
    
    const day_utc = convertDateToUTC(new Date(date));
    const is_Today = isToday(day_utc);
    const day_numberq = new Date(day_utc).getDay();
    const schedules = currentType.type_areas;
    const day_schedule = currentType.type_shedule.filter((day) => day.day_number === String(day_numberq));

    const result = [];

    try {
      data.forEach((obj) => {
        
        const checkGameOption = getGameOptionsVerify(currentType.id, obj.type_area_id);

        const list_hours = [];
        const disable_hours = [];

        const area_loop = schedules.find((area) => area.id === obj.type_area_id);
        const reservations = data.find((rest) => rest.type_area_id === obj.type_area_id);
        const open = Number(moment(day_schedule[0].open, "h:mm:ss").format("HH"));
        const last_schedule = Number(moment(day_schedule[0].last_schedule, "h:mm:ss").format("HH"));

        area_loop.typearea_option.forEach((typearea_option) => {
          const disable = typearea_option.typearea_shedule.find((day) => day.day_number === String(day_numberq));

          const dHArray = disable.disable_hours.split(",");

          dHArray.forEach((hour) => {
            const person = disable_hours.find((element) => {
              if (element.hour === String(moment({ hour }).format("HH:mm:ss"))) {
                return true;
              }
              return false;
            });

            if (person === undefined) {
              disable_hours.push({
                id: "disabled",
                hour: String(moment({ hour }).format("HH:mm:ss")),
              });
            }
          });
        });

        for (let hour = open; hour <= last_schedule; hour++) {
          let reservation_check = reservations.reservations.find((reservation) => reservation.time_arrival === String(moment({ hour }).format("HH:mm:ss")));

          if (reservation_check) {
            if (reservation_check.status_id === 2) {
              const start = Number(moment(reservation_check.time_arrival, "HH:mm:ss").format("k"));
              const end = Number(moment(reservation_check.time_exit, "HH:mm:ss").format("k"));

              for (var i = start; i <= end; i++) {
                // eslint-disable-next-line no-loop-func
                const filteredArray = disable_hours.filter((element) => {
                  if (element.hour === moment(i, "h").format("HH:mm:ss")) {
                    return true;
                  }
                  return false;
                });

                if (!filteredArray.length > 0 && i !== end) {
                  disable_hours.push({
                    id: reservation_check.id,
                    hour: String(moment(i, "h").format("HH:mm:ss")),
                  });
                }
              }
            }
          }

          const nowDate = Number(moment().tz("America/Panama").format("HH"));

          const filteredArray2 = disable_hours.filter((element) => {
            if (element.hour === String(moment({ hour }).format("HH:mm:ss"))) {
              return true;
            }
            return false;
          });

          if (!filteredArray2.length > 0) {
            if (hour <= nowDate && is_Today === true) {
              disable_hours.push({
                id: "ended",
                hour: String(moment({ hour }).format("HH:mm:ss")),
              });
            }
          }
        }

        const activeGameHours = [];

        if (checkGameOption > 0) {
          const games = schedules.find((_type) => _type.id === obj.type_area_id);

          games.game_options.forEach((obj) => {
            if (obj.type_games_option_shedule.length > 0) {
              const games_shedule = obj.type_games_option_shedule.find((_type) => _type.day_number === String(day_numberq));
              const allDay = games_shedule.all_day;
              const gameActive = games_shedule.status;

              if (games_shedule.active_hours && allDay === false) {
                const activeHours = games_shedule.active_hours.split(",");
                activeHours.forEach((hour) => {
                  activeGameHours.push({
                    hour: moment({ hour }).format("h:mm a"),
                    status: gameActive,
                    game: obj.name,
                  });
                });
              }
            }
          });
        }

        for (let hour = open; hour <= last_schedule; hour++) {
          var hour_str = String(moment({ hour }).format("h:mm a"));
          var game_active = null;

          // eslint-disable-next-line no-loop-func
          const isGameInArray = activeGameHours.find((element) => {
            return element.hour === hour_str && element.status === 1;
          });

          if (isGameInArray) {
            game_active = isGameInArray.game;
          }

          const isInArray = disable_hours.filter((element) => {
            if (element.hour === String(moment({ hour }).format("HH:mm:ss"))) {
              return true;
            }
            return false;
          });

          if (isInArray.length > 0) {
            if (isInArray[0].id !== "disabled") {
              if (isInArray[0].id === "ended") {
                list_hours.push({
                  hour: hour_str,
                  status: "disabled",
                  id: null,
                  open: "No Disponible",
                  game_active: game_active,
                });
              } else if (typeof isInArray[0].id === "number") {
                list_hours.push({
                  hour: hour_str,
                  status: "disabled",
                  id: isInArray[0].id,
                  open: "Reservado",
                  game_active: game_active,
                });
              }
            }
          } else {
            list_hours.push({
              hour: hour_str,
              status: "open",
              id: null,
              open: "Disponible",
              game_active: game_active,
            });
          }
        }

        if (area_loop.name == "Cancha #4 (No Techada)") {
          result.push({
            name: area_loop.name,
            area_id: area_loop.id,
            list_hours: list_hours,
          });
        }
      });

      setListHouresType(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = async (e, id, hour, game) => {
    const select_date = e.currentTarget.value;

    setIsLoading(true);

    setValues((prevState) => ({
      ...prevState,
      exit: "none",
      type_area: "none",
      game_option: "none",
      game_option_name: "none",
    }));

    if (id) {
      setValues((prevState) => ({
        ...prevState,
        type_area: id,
      }));

      if (hour) {
        handleChanges("arrival_time", hour);
      }

      const games = generateGameOptions(id, game);

      setGameTypeOptions(games);

      if (game) {
        const insideGame = games.find((gm) => gm.name === game);

        setValues((prevState) => ({
          ...prevState,
          game_option_type: insideGame.id,
        }));
      }

      var add_hour = moment(hour, "h:mm a").add(1, "hours").format("h:mm a");

      var areaOptonsType = currentType.type_areas.find((area) => area.id === id);
      var area_select = listHourestype.find((area) => area.area_id === id);
      var check_doble = area_select.list_hours.find((area) => area.hour === add_hour);

      if (typeof check_doble !== "undefined") {
        var list_options = [];

        areaOptonsType.typearea_option.forEach((element) => {
          if (element.name === "Sencillo") {
            list_options.push(element);
          } else if (element.name === "Doble" && check_doble.status === "open") {
            list_options.push(element);
          }
        });

        setGameOptions(list_options);
      } else {
        setGameOptions(areaOptonsType.typearea_option);
      }

      setOptionEnable(false);
    }

    if (activeStep === 0) {
      setInsideGameActive(false);
      const day_utc = convertDateToUTC(new Date(select_date));
      setArrivalDate(new Date(day_utc));
      const day_number = new Date(day_utc).getDay();

      const form = {
        type: values.type,
        date: select_date,
        daynumber: day_number,
      };

      try {
        await axios({
          method: "POST",
          url: "/api/forms/typeAvailabilityCheck/",
          data: form,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
          .then((response) => {
            /*handleServerResponse(true, response.data.detail);*/
            generateHoursList(response.data, select_date);
            setAvailabilityCheck(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setAvailabilityCheck(false);
            /*handleServerResponse(false, error.response.data.detail);*/
          });
      } catch (err) {
        console.log(err);
      }
    }

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const recheck = async (date, id, hour) => {
    var select_date = moment(date).format("YYYY-MM-DD");

    setIsLoading(true);

    const day_utc = convertDateToUTC(new Date(select_date));
    setArrivalDate(new Date(day_utc));
    const day_number = new Date(day_utc).getDay();

    const form = {
      type: values.type,
      date: select_date,
      daynumber: day_number,
    };

    try {
      await axios({
        method: "POST",
        url: "/api/forms/typeAvailabilityCheck/",
        data: form,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
        .then((response) => {
          /*handleServerResponse(true, response.data.detail);*/
          generateHoursList(response.data, select_date);
          setAvailabilityCheck(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setAvailabilityCheck(false);
          /*handleServerResponse(false, error.response.data.detail);*/
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsLoading(false);

    const tab = e - 1;

    if (tab === 1) {
      recheck(arrivalDate, values.type_area);
      setExitEnable(true);
      setServerState();
      setInsideGameActive(false);
      setValues((prevState) => ({
        ...prevState,
        game_option_type: 1,
      }));
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };

  const [errors, setErrors] = useState({
    exit: true,
    terms: true,
    users: true,
  });

  const [values, setValues] = useState({
    type: typeId,
    name: userInfo.first_name,
    last_name: userInfo.last_name,
    email: userInfo.email,
    club_id: userInfo.club_id,
    arrival_date: arrivalDate,
    arrival_time: "none",
    exit: "none",
    type_area: "none",
    game_option: "none",
    game_option_name: "none",
    game_option_type: 1,
    users: [],
    comentarios: "",
    terms: false,
  });

  const getMinDays = (id) => {
    const info = type.find((_type) => _type.id === typeId);

    if (info) {
    }
  };

  const disableDays2 = (id) => {
    var days = [];

    let info_change = type.find((_type) => _type.id === id);
    info_change.type_shedule.map((option, index) => {
      if (option.status == 0) {
        days.push(Number(option.day_number));
      }
    });

    setDaysoff(days);
  };

  const disableDays = (date) => {
    return daysoff.indexOf(date.getDay()) != -1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = {
      user: userInfo.user_id,
      created_at: moment(new Date()).format(),
      type: values.type,
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      club_id: values.club_id,
      arrival_date: moment(arrivalDate).format("YYYY-MM-DD"),
      arrival_time: values.arrival_time,
      type_area: values.type_area,
      game_option: values.game_option,
      game_option_type: values.game_option_type,
      exit: values.exit,
      users: values.users,
      comentarios: values.comentarios,
      terms: values.terms,
      errors: errors,
    };

    try {
      setLoading(true);
      setServerState();

      await axios({
        method: "POST",
        url: "/api/forms/submitTypeform/",
        data: form,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
        .then((response) => {
          handleServerResponse(true, response.data.detail);

          resetForm();
          setValues((prevState) => ({
            ...prevState,
            type_area: "none",
          }));

          setErrors((prevState) => ({
            exit: true,
            terms: true,
            users: true,
          }));

          setOptionEnable(true);
          setLoading(false);

          setTimeout(() => {
            setServerState();
            setActiveStep(0);
            setIsLoading(false);
          }, 6000);
        })
        .catch((error) => {
          handleServerResponse(false, error.response.data.detail);
          setLoading(false);
        });
    } catch (err) {
      if (mounted.current) {
        setSubmitErrors({
          submit: err.response && err.response.data.detail ? err.response.data.detail : err.message,
        });
      }
    }
  };

  const checkAvailability = async (e) => {
    setAvailability(true);

    const form = {
      user: userInfo.user_id,
      type: values.type,
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      club_id: values.club_id,
      arrival_date: values.arrival_date,
      arrival_time: values.arrival_time,
      type_area: values.type_area,
      game_option: values.game_option,
      exit: values.exit,
      users: values.users,
      comentarios: values.comentarios,
      terms: values.terms,
      errors: errors,
    };

    try {
      setAvailability(true);

      await axios({
        method: "POST",
        url: "/api/forms/typeAvailability/",
        data: form,
      })
        .then((response) => {
          setAvailability(false);
          setAvailabilityStatus(true);
        })
        .catch((error) => {
          setAvailability(false);
        });
    } catch (err) {
      if (mounted.current) {
      }
    }
  };

  const handleArrivalChange = (e) => {
    setArrivalDate(new Date(e));

    setValues((prevState) => ({
      ...prevState,
      arrival_time: "none",
      exit: "none",
    }));

    if (gameOptions.length > 0) {
      var areaOptonsType = gameOptions.find((option) => option.id === values.game_option);
      generateHours(e, areaOptonsType.typearea_shedule);
    }
  };

  const isToday = (date) => {
    const today = new Date();
    const someDate = new Date(date);

    return someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear();
  };

  const generateExitHours = (date, selectDate, gameOption) => {
    var areaOptonsType = {};

    if (gameOption) {
      areaOptonsType = gameOptions.find((option) => option.id === gameOption);
    } else {
      areaOptonsType = gameOptions.find((option) => option.id === values.game_option);
    }

    const today = new Date();
    const selectDay = new Date(date);
    const day_numberq = new Date(date).getDay();
    let schedules = currentType.type_shedule;
    let day_schedule = schedules.filter((day) => day.day_number === String(day_numberq));

    const exithours = [];

    var closed = moment(day_schedule[0].closed, "h:mm:ss").format("HH");

    if (closed == "00") {
      var closed = 24;
    }

    if (selectDate) {
      let selectDateH = Number(moment(selectDate, "h:mm a").format("HH"));
      let selectDateLimit = Number(moment(selectDate, "h:mm a").add(areaOptonsType.max_time, "hours").format("HH"));
      let selectDateMins = moment(selectDate, "h:mm a").format("mm");

      if (selectDateLimit == 0) {
        selectDateLimit = 24;
      }

      for (let hour = selectDateH; hour <= selectDateLimit; hour++) {
        var h1 = moment({ hour }).format("HH");
        var h2 = moment(selectDate, "h:mm a").format("HH");
        var h3 = moment({ hour }).format("hh:mm a");
        var h4 = moment(selectDate, "h:mm a").format("hh:mm a");
        var time30 = moment({ hour, minute: 30 }).format("hh:mm a");

        if (h1 != "00") {
          if (h1 > h2) {
            if (h1 <= closed) {
              exithours.push(moment(h1, "HH").format("h:mm a"));
            }
          }
          /*if (selectDateMins == 30) {

            if (h4 != time30) {
              exithours.push(moment({ hour, minute: 30 }).format('h:mm a'));
            }

          } else {

            if (selectDateLimit != Number(h1)) {
              exithours.push(moment({ hour, minute: 30 }).format('h:mm a'));
            }

          }*/
        } else {
          exithours.push(moment(0, "HH").format("h:mm a"));
        }
      }
      setExitHours(exithours);
      setExitEnable(false);
      checkExit(selectDate);
    }
  };

  const handleErros = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value ? false : true,
    }));
  };

  const handleChanges = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkExit = (exit) => {
    if (exitHours.includes(exit) == false) {
      handleChanges("exit", "none");

      setErrors((prevState) => ({
        ...prevState,
        exit: true,
      }));
    }
  };

  const createElements = (n) => {
    var user_name = userInfo.first_name + " " + userInfo.last_name;
    var data = [...values.users];
    var new_number = n;
    var elements = [];

    if (values.users.length == 0) {
      for (let user = 0; user < n; user++) {
        if (user == 0) {
          elements.push({
            name: user_name,
            id: userInfo.club_id,
            disabled: true,
            require: true,
            error: false,
            formid: `${user}_user`,
          });
        } else {
          elements.push({
            name: "",
            id: "",
            require: true,
            error: true,
            formid: `${user}_user`,
          });
        }
      }

      handleChanges("users", elements);
    } else {
      if (n > values.users.length) {
        for (let user = 0; user < n; user++) {
          if (data[user] == undefined) {
            data.push({
              name: "",
              id: "",
              require: true,
              disabled: true,
              error: true,
              formid: `${user}_user`,
            });
          }
        }
        handleChanges("users", data);
      } else {
        var reset = [];
        for (let user = 0; user < data.length; user++) {
          if (n <= user) {
          } else {
            reset.push(data[user]);
          }
        }
        handleChanges("users", reset);
      }
    }
  };

  const filterArrayElementByEdit = (name) => {
    return friends.filter((element) => {
      return element.name === name;
    });
  };

  const handelUserChange = (e, idform, nameid, reason) => {
    try {
      let name = nameid.includes("user_name");
      let id = nameid.includes("user_id");

      let data = [...values.users];

      let index = data.findIndex((obj) => obj.formid === idform);

      if (name) {
        let check_name = filterArrayElementByEdit(e.target.value);

        if (check_name.length === 1) {
          data[index].id = String(check_name[0].buddy);
        }

        data[index].name = e.target.value;

        if (reason === "clear") {
          data[index].id = "";
        }
      }

      if (id) {
        data[index].id = e.target.value;
      }

      if (data[index].name != "" && data[index].id != "") {
        data[index].error = false;
      } else {
        data[index].error = true;
      }

      handleChanges("users", data);
      handlefriends(friends, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (e) => {
    let infores = type.find((page) => page.id === e);

    setFormType(e);
    setExitEnable(true);
    disableDays2(e);
    generateHours(values.arrival_date, infores);

    setValues((prevState) => ({
      ...prevState,
      type: e,
      name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      club_id: userInfo.club_id,
      arrival_date: arrivalDate,
      arrival_time: "none",
      exit: "none",
      cancha: "none",
      users: [],
      comentarios: "",
      terms: false,
    }));

    setErrors((prevState) => ({
      ...prevState,
      exit: true,
      terms: true,
      users: true,
    }));
  };

  const resetForm = () => {
    setValues((prevState) => ({
      ...prevState,
      arrival_time: "none",
      exit: "none",
      game_option: "none",
      users: [],
      comentarios: "",
      game_option_type: 1,
      terms: false,
    }));
    setResHours([]);
    setExitHours([]);
    setExitEnable(true);

    setArrivalEnable(true);
  };

  const hangleAreaTypechange = (e) => {
    var areaOptonsType = currentType.type_areas.find((area) => area.id === e);

    setGameOptions(areaOptonsType.typearea_option);

    setValues((prevState) => ({
      ...prevState,
      arrival_time: "none",
      exit: "none",
    }));

    if (values.game_option !== "none") {
      var current = areaOptonsType.typearea_option.find((area) => area.name === values.game_option_name);

      setValues((prevState) => ({
        ...prevState,
        game_option: current.id,
        game_option_name: current.name,
      }));

      hangleAreaOptionTypechange(current.id, areaOptonsType.typearea_option);
    }

    setOptionEnable(false);
  };

  const hangleAreaOptionTypechange = (e, options) => {
    var areaOptonsType = {};

    if (options) {
      areaOptonsType = options.find((option) => option.id === e);
    } else {
      areaOptonsType = gameOptions.find((option) => option.id === e);
    }

    generateHours(arrivalDate, areaOptonsType.typearea_shedule);

    setArrivalEnable(false);

    if (values.exit !== "none" && values.arrival_time !== "none") {
      if (options) {
        setExitEnable(true);
      } else {
        generateExitHours(arrivalDate, values.arrival_time, e);
      }
    }

    if (values.exit === "none" && values.arrival_time !== "none") {
      generateExitHours(arrivalDate, values.arrival_time, e);
    }
  };

  const checkDayCalendar = () => {
    const today = new Date();
    const is_Today = isToday(today);
    let day_hour = new Date().getHours();
    const day_numberq = new Date(today).getDay();
    let schedules = currentType.type_shedule;
    let day_schedule = schedules.filter((day) => day.day_number === String(day_numberq));

    var startTimeinit = moment(today, "HH:mm:ss a");
    var endTimeinit = moment(day_schedule[0].last_schedule, "HH:mm:ss a");

    var difinit = moment.duration(endTimeinit.diff(startTimeinit));
    var minutesinit = difinit.hours() * 60 + difinit.minutes();

    if (minutesinit < 60 && day_hour <= 23 && is_Today == true) {
      var from_date = today.setDate(today.getDate() + 1);
      setArrivalDate(new Date(from_date));
      setMinDate(new Date(from_date));
    }
  };

  const addCountdaownDays = (index, time) => {
    switch (index) {
      case 7:
        return moment(time).add(0, "days").set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      case 8:
        return moment(time).add(1, "days").set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      default:
        return null;
    }
  };

  const checkListDays = (time) => {
    setDaysLoopForm([]);
    setIsLoading(true);

    const daysLoop = [];
    const day_numberq = time.day();
    const schedules = currentType.type_shedule;
    const day_schedule = schedules.filter((day) => day.day_number === String(day_numberq));
    const last_schedule = Number(moment(day_schedule[0].last_schedule, "h:mm:ss").format("HH"));

    const maxDays = addDays(moment(time).format(), currentType.max_days);

    var b = moment(addDays(maxDays, 2));
    var c = moment(addDays(maxDays, 1));
    var d = Number(time.format("HH"));

    let index = 0;

    for (var m = moment(time); m.diff(b, "days") <= 0; m.add(1, "days")) {
      index += 1;

      if (m < c) {
        if (isToday(m) === true) {
          if (d < last_schedule) {
            daysLoop.push({
              date: m.locale("es").format("YYYY-MM-DD"),
              disabled: false,
              countdown: addCountdaownDays(index, time),
            });
          } else {
            daysLoop.push({
              date: m.locale("es").format("YYYY-MM-DD"),
              disabled: true,
              countdown: null,
            });
          }
        } else {
          daysLoop.push({
            date: m.locale("es").format("YYYY-MM-DD"),
            disabled: false,
            countdown: null,
          });
        }
      } else {
        var t = moment(time).tz("America/Panama");
        var cc = addCountdaownDays(index, time);

        if (cc < t) {
          daysLoop.push({
            date: m.locale("es").format("YYYY-MM-DD"),
            disabled: false,
            countdown: null,
          });
        } else {
          daysLoop.push({
            date: m.locale("es").format("YYYY-MM-DD"),
            disabled: true,
            countdown: addCountdaownDays(index, time),
          });
        }
      }
    }

    setDaysLoopForm(daysLoop);
    setIsLoading(false);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      checkListDays(moment().tz("America/Panama"));
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const handleReserved = async (id) => {
    if (id) {
      setReservedInfoOpen(true);
      setReservedInfoID(id);

      await axios({
        method: "POST",
        url: "/api/reservation/usersInfo/",
        data: { id: id },
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
        .then((response) => {
          setReservedInfoOpenData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReservedClose = () => {
    setReservedInfoOpen(false);
    setReservedInfoOpenData();
  };

  const generateOptions = () => {
    const list_hours = [];

    try {
      const day_number = new Date(arrivalDate).getDay();

      const dHArray = gameOptions.find((option) => option.name === "Doble");

      if (typeof dHArray !== "undefined") {
        const shedule = dHArray.typearea_shedule.find((option) => option.day_number === String(day_number));
        if (shedule.end_hours) {
          const end_hours = shedule.end_hours.split(",");
          const select_hour = Number(moment(values.arrival_time, "h:mm a").format("HH"));
          const is_endhour = end_hours.includes(String(select_hour));

          gameOptions.forEach((option, index) => {
            if (option.name == "Doble" && is_endhour == false) {
              list_hours.push(option);
            } else if (option.name == "Sencillo") {
              list_hours.push(option);
            }
          });
        } else {
          gameOptions.forEach((option, index) => {
            list_hours.push(option);
          });
        }
      } else {
        gameOptions.forEach((option, index) => {
          list_hours.push(option);
        });
      }
    } catch (error) {
      console.log(error);
    }

    return (
      list_hours &&
      list_hours.map((option, index) =>
        option.status === 1 ? (
          <MenuItem key={index} value={option.id}>
            {option.name}
          </MenuItem>
        ) : null
      )
    );
  };

  const generateGameOptions = (id, game) => {
    const game_list = [];

    try {
      const checkGameOption = getGameOptionsVerify(currentType.id, id);

      if (checkGameOption > 0) {
        setInsideGameActive(true);
        const games = currentType.type_areas.find((_type) => _type.id === id);
        games.game_options.forEach((option) => {
          if (game) {
            if (option.name === game) {
              game_list.push(option);
            }
          } else {
            game_list.push(option);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    return game_list;
  };

  const handlefriends = (obj, users) => {
    const typeFriends = obj.filter((element) => element.type_id == typeId);
    setFilterFriends(typeFriends);

    const list = [];

    if (users != null) {
      typeFriends.forEach((friend) => {
        let user_vf = users.filter((user) => user.name === String(friend.name));
        if (user_vf.length == 0) {
          list.push(friend);
        }
      });
      setFilterFriends(list);
    }
  };

  const getGameOptionsVerify = (typeId, typeAreaId) => {
    const info = type.find((_type) => _type.id === typeId)?.type_areas?.find((_type) => _type.id === typeAreaId);
    return info?.game_options?.length || 0;
  };

  useEffect(() => {
    if (localTime) {
      disableDays2(formType);
      checkDayCalendar();
      handlefriends(friends, null);
      setMaxDate(addDays(moment(localTime).format(), currentType.max_days));
      checkListDays(moment(localTime, "YYYY-MM-DD HH:mm:ss"));
    }
  }, [localTime]);

  useEffect(() => {
    if (Object.values(errors).includes(true)) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [errors]);

  useEffect(() => {
    if (values.users.some((e) => e.error === true)) {
      setErrors((prevState) => ({
        ...prevState,
        users: true,
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        users: false,
      }));
    }
  }, [values.users]);

  return (
    <>
      {restInitialized ? (
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation={isMobile ? "vertical" : "horizontal"}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pb: 2 }}>
            <Button color="primary" size="small" disabled={activeStep === 0} onClick={(e) => handleBack(activeStep)} variant="outlined" startIcon={<ChevronLeftIcon />}>
              Volver
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? <></> : <>{isLoading && <CircularProgress size={30} />}</>}
          </Box>
          <React.Fragment>
            {activeStep === 0 && (
              <Stack
                spacing={2}
                sx={{
                  mt: 1,
                }}
              >
                {daysLoopForm.length !== 0 ? (
                  daysLoopForm.map((date, index) => {
                    return (
                      <>
                        <Button variant="outlined" key={index} value={date.date} onClick={handleNext} disabled={date.disabled} sx={{}}>
                          {moment(date.date).locale("es").format("dddd, D [de] MMM, YYYY")}

                          {date.disabled && date.countdown != null && (
                            <Tooltip title="Countdown" size="small" sx={{ ml: 0 }}>
                              <Box sx={{ p: 0, m: 0, ml: 1 }} size="small">
                                {" "}
                                <Countdown date={moment(date.countdown).format()} renderer={renderer} />
                              </Box>
                            </Tooltip>
                          )}
                        </Button>
                      </>
                    );
                  })
                ) : (
                  <Box>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                  </Box>
                )}
              </Stack>
            )}
            {activeStep === 1 &&
              (availabilityCheck === true && listHourestype !== [] ? (
                <>
                  {listHourestype.map((option, index) => (
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className={classes.root}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                        <Typography>{option.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <List
                          sx={{
                            width: "100%",
                            mt: 1,
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 0,
                          }}
                        >
                          {availabilityCheck
                            ? option.list_hours.map((value, index) => {
                                return (
                                  <>
                                    <Divider />
                                    <ListItem key={index}>
                                      <ListItemText>
                                        {value.hour} {value.game_active && `(${value.game_active})`}
                                      </ListItemText>
                                      {value.open === "Reservado" || value.open === "No Disponible" ? (
                                        <Button variant="text" onClick={(e) => handleReserved(value.id)} color={value.open === "Reservado" || value.open === "No Disponible" ? "error" : "primary"}>
                                          {value.open}
                                        </Button>
                                      ) : (
                                        <Button variant="text" onClick={(e) => handleNext(e, option.area_id, value.hour, value.game_active)} color={value.open == "Reservado" ? "error" : "primary"}>
                                          {value.open}
                                        </Button>
                                      )}
                                    </ListItem>
                                  </>
                                );
                              })
                            : ""}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <Modal
                    open={reservedInfoOpen}
                    onClose={handleReservedClose}
                    aria-labelledby="modal-reservation-info"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={reservedInfoOpen}>
                      <Box sx={style}>
                        {reservedInfoOpenData && reservedInfoID ? (
                          <ListUsersReservation users={reservedInfoOpenData} resid={reservedInfoID} />
                        ) : (
                          <Box
                            sx={{ mt: 2 }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </Box>
                    </Fade>
                  </Modal>
                </>
              ) : (
                <Box sx={{ mt: 2 }} style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ))}
            {activeStep === 2 && (
              <form onSubmit={handleSubmit} id="sugerenciasForm">
                {insideGameActive === true && gameTypeOptions.length === 1 && (
                  <Alert severity="warning">Estimado/a socio/a, tenga en cuenta que la hora seleccionada está habilitada únicamente para juegos de Pickleball.</Alert>
                )}

                <Grid container sx={{ mt: 0 }} spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "none",
                    }}
                  >
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Nombre *
                    </Typography>
                    <TextField fullWidth disabled name="name" required value={values.name} variant="outlined" />
                  </Grid>
                  <Grid
                    sx={{
                      display: "none",
                    }}
                    item
                    xs={12}
                    sm={6}
                  >
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Apellido *
                    </Typography>
                    <TextField fullWidth disabled value={values.last_name} name="last_name" variant="outlined" />
                  </Grid>
                  <Grid
                    sx={{
                      display: "none",
                    }}
                    item
                    xs={12}
                    sm={6}
                  >
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Email *
                    </Typography>
                    <TextField fullWidth disabled value={values.email} name="email" type="email" variant="outlined" />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "none",
                    }}
                    xs={12}
                    sm={6}
                  >
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Número de Socio *
                    </Typography>
                    <TextField fullWidth disabled value={values.club_id} name="club_id" />
                  </Grid>
                  <Grid item xs={12} sm={insideGameActive ? 6 : 12}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Fecha *
                    </Typography>
                    <LocalizationProvider dateAdapter={DateAdapter} locale={esLocale}>
                      <DesktopDatePicker
                        name="arrival"
                        required
                        disablePast
                        disabled={true}
                        shouldDisableDate={disableDays}
                        onChange={(e) => {
                          handleArrivalChange(e);
                        }}
                        maxDate={maxDate}
                        minDate={minDate}
                        value={arrivalDate}
                        renderInput={(inputProps) => <TextField fullWidth variant="outlined" {...inputProps} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {insideGameActive && (
                    <Grid item xs={12} sm={6}>
                      <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                        Juego
                      </Typography>
                      <Select
                        fullWidth
                        variant="outlined"
                        name="game_option_type"
                        disabled={gameTypeOptions.length > 1 ? false : true}
                        value={values.game_option_type}
                        onChange={(game) => {
                          setValues((prevState) => ({
                            ...prevState,
                            game_option_type: game.target.value,
                          }));
                        }}
                      >
                        <MenuItem value="none" disabled>
                          Seleccione una opción...
                        </MenuItem>
                        {gameTypeOptions &&
                          gameTypeOptions.map((option, index) =>
                            option.status === 1 ? (
                              <MenuItem key={index} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Cancha *
                    </Typography>

                    <Select fullWidth variant="outlined" name="cancha" disabled={true} value={values.type_area}>
                      <MenuItem value="none" disabled>
                        Seleccione una opción...
                      </MenuItem>
                      {currentType.type_areas.map((option, index) =>
                        option.status === 1 ? (
                          <MenuItem key={index} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ) : (
                          <MenuItem key={index} value={option.id} disabled>
                            {option.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Tipo de Juego *
                    </Typography>
                    <Select
                      fullWidth
                      variant="outlined"
                      name="game_option"
                      disabled={optionEnable}
                      onChange={(date) => {
                        var areaOptonsType = gameOptions.find((option) => option.id === date.target.value);
                        setValues((prevState) => ({
                          ...prevState,
                          game_option: date.target.value,
                          game_option_name: areaOptonsType.name,
                        }));
                        hangleAreaOptionTypechange(date.target.value);
                        generateExitHours(arrivalDate, values.arrival_time, date.target.value);
                        createElements(areaOptonsType.persons);
                      }}
                      value={values.game_option}
                    >
                      <MenuItem value="none" disabled>
                        Seleccione una opción...
                      </MenuItem>
                      {generateOptions()}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Hora de Llegada *
                    </Typography>

                    <TextField fullWidth disabled={true} value={values.arrival_time} name="arrival" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Hora de Salida *
                    </Typography>
                    <Select
                      fullWidth
                      variant="outlined"
                      name="exit"
                      displayEmpty
                      disabled={exitEnable}
                      onChange={(date) => {
                        handleChanges("exit", date.target.value);
                        handleErros("exit", date.target.value);
                      }}
                      value={values.exit}
                      defaultValue="none"
                      error={Boolean(errors.exit)}
                    >
                      <MenuItem value="none" disabled>
                        Seleccione una opción...
                      </MenuItem>
                      {exitHours.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {values.game_option != "none" ? (
                    <Grid item xs={12}>
                      {values.users.length > 1 && (
                        <Alert
                          severity="warning"
                          sx={{
                            mb: 3,
                          }}
                        >
                          1. Los socios participantes deben estar registrados y aprobados en nuestro sitio web.
                          <br />
                          <br />
                          2. El campo de "número de socio" en su lista de socios participantes, debe ser los dígitos seguidos de los dos (2) primeros números.
                        </Alert>
                      )}

                      {values.users.length > 0
                        ? values.users.map((option, index) => (
                            <Card sx={{ mb: 3 }}>
                              <CardContent>
                                <Grid
                                  container
                                  align="center"
                                  justify="center"
                                  alignItems="center"
                                  spacing={{ xs: 0, md: 4 }}
                                  sx={{
                                    mb: {
                                      md: 0,
                                      xs: 0,
                                    },
                                  }}
                                >
                                  <Grid item xs={12} sm={6}>
                                    {index == 0 ? (
                                      <TextField
                                        label="Nombre Completo"
                                        id={option.formid}
                                        disabled={index == 0 ? true : false}
                                        fullWidth
                                        variant="standard"
                                        name={`${index}_user_name`}
                                        defaultValue={option.name != "" ? option.name : ""}
                                      />
                                    ) : (
                                      <Autocomplete
                                        freeSolo
                                        id={option.formid}
                                        options={filterFriends.map((option) => option.name)}
                                        clearText="Borrar"
                                        forcePopupIcon={false}
                                        onInputChange={(e, newInputValue, reason) => {
                                          if (reason == "clear") {
                                            handelUserChange(e, option.formid, `${index}_user_name`, reason);
                                          }
                                        }}
                                        disabled={index == 0 ? true : false}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            value={option.name != "" ? option.name : ""}
                                            variant="standard"
                                            label="Nombre Completo"
                                            error={option.error}
                                            fullWidth
                                            name={`${index}_user_name`}
                                            onSelect={(e) => {
                                              handelUserChange(e, option.formid, `${index}_user_name`);
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label="Número de socio"
                                      id={option.formid}
                                      disabled={index == 0 ? true : false}
                                      fullWidth
                                      error={option.error}
                                      name={`${index}_user_id`}
                                      variant="standard"
                                      onChange={(e) => {
                                        handelUserChange(e, option.formid, `${index}_user_id`);
                                      }}
                                      value={option.id != "" ? option.id : ""}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          ))
                        : ""}
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                      Comentarios
                    </Typography>
                    <TextField
                      fullWidth
                      name="comentarios"
                      multiline
                      rows={3}
                      disabled={isAuthenticated == false ? true : false}
                      variant="outlined"
                      onChange={(data) => {
                        handleChanges("comentarios", data.target.value);
                      }}
                      value={values.comentarios}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "block",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      pb: {
                        xs: 1,
                      },
                    }}
                  ></Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: 0,
                      pb: {
                        xs: 3,
                      },
                    }}
                  >
                    <Checkbox
                      checked={values.terms}
                      color="primary"
                      name="policy"
                      onChange={(e) => {
                        handleChanges("terms", e.target.checked);
                        handleErros("terms", e.target.checked);
                      }}
                    />
                    <Typography color="textSecondary" variant="body2">
                      Al realizar esta reserva usted acepta nuestros{" "}
                      <Button
                        disableFocusRipple
                        color="primary"
                        sx={{
                          p: 0,
                        }}
                        style={{ color: "#2383AC" }}
                        onClick={handleClickOpen("paper")}
                      >
                        Términos y Condiciones
                      </Button>
                    </Typography>
                  </Box>
                  <LoadingButton disableFocusRipple color="primary" fullWidth size="large" type="submit" disabled={isAuthenticated && validate ? false : true} variant="contained" loading={loading}>
                    Enviar
                  </LoadingButton>
                </Box>
                {serverState && (
                  <Box sx={{ mt: 3, mb: 0 }}>
                    <Alert severity={!serverState.ok ? "error" : "success"}>{serverState.msg}</Alert>
                  </Box>
                )}
                <Dialog open={open2} onClose={handleCloseD} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                  <DialogTitle id="scroll-dialog-title">Términos y Condiciones</DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} align={"justify"}>
                      <Terminosycondiciones />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disableFocusRipple
                      onClick={(e) => {
                        handleCloseD();
                        handleChanges("terms", false);
                        handleErros("terms", null);
                      }}
                      color="primary"
                    >
                      Cancelar
                    </Button>
                    <Button
                      disableFocusRipple
                      onClick={(e) => {
                        handleCloseD();
                        handleChanges("terms", true);
                        handleErros("terms", true);
                      }}
                      color="primary"
                    >
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          </React.Fragment>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default PicklballSportsForm;
