import { useDispatch, useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ViewerGuard = ({ children }) => {
  const authState = useSelector(state => state.userLogin)

  const { userInfo } = authState

  if (userInfo.role !== "Viewer") {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

ViewerGuard.propTypes = {
  children: PropTypes.node
};

export default ViewerGuard;
