import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { logOutDashboard } from './dashboard'

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false;

const initialState = {
    isAuthenticated: userInfoFromStorage ? true : false,
    isInitialized: false,
    friendsInitialized: false,
    resInitialized: false,
    defaulting: false,
    age: '',
    approval: [],
    suspensions: [],
    reservations: [],
    friends: [],
    userInfo: userInfoFromStorage,
};

const slice = createSlice({
    name: 'userLogin',
    initialState,
    reducers: {
        isAuthenticated(state) {
            state.isAuthenticated = true;
            state.isInitialized = true;
        },
        getUserReservations(state, action) {
            state.reservations = action.payload
            state.resInitialized = true
        },
        isInitialized(state) {
            state.isInitialized = true;
        },
        loginSucces(state, action) {
            state.isAuthenticated = true;
            state.isInitialized = true;
            state.userInfo = action.payload
        },
        userLogout(state) {
            state.defaulting = false;
            state.isAuthenticated = false;
            state.isInitialized = true;
            state.userInfo = [];
            state.reservations = [];
            state.approval = [];
            state.friends = [];
            state.age = '';
            localStorage.removeItem('userInfo');
            localStorage.removeItem('fslightbox-types');
        },
        getDefaulting(state, action) {
            if (action.payload.defaulting != "F") {
                state.defaulting = true;
            }
        },
        approvalCheck(state, action) {
            state.approval = action.payload
        },
        getSuspensionStatus(state, action) {
            state.suspensions = action.payload
        },
        resetPasswordConfirm(state, action) {

        },
        resetPassword(state, action) {

        },
        updateUserProfile(state, action) {
            state.userInfo = action.payload
        },
        getFriends(state, action) {
            state.friends = action.payload
            state.friendsInitialized = true
        },
        getUserAge(state, action) {
            state.age = action.payload
        },
        updateAvatar(state, action) {
            state.userInfo = { ...state.userInfo, ['avatar']: action.payload }
        }
    }
});

export const { reducer } = slice;

export const isInitialized = () => async (dispatch) => {
    dispatch(slice.actions.isInitialized());
};

export const isAuthenticated = () => async (dispatch) => {
    dispatch(slice.actions.isAuthenticated());
};

export const loginSucces = (data) => async (dispatch) => {
    dispatch(slice.actions.loginSucces(data));
};

export const userLogin = (email, password) => async (dispatch) => {

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
    
    const { data } = await axios.post(
        '/api/users/login/',
        { 'username': email, 'password': password },
        config
    );

    dispatch(slice.actions.loginSucces(data))

    localStorage.setItem('userInfo', JSON.stringify(data));

    return data;

};

export const userLogout = () => async (dispatch) => {
    dispatch(slice.actions.userLogout());
    dispatch(logOutDashboard());
};

export const userRegister = (email, name, lastname, password, idclub, cardimage, cedula, phone, day, month, year) => async (dispatch) => {
    const formData = new FormData();
    formData.append('email', email)
    formData.append('name', name)
    formData.append('lastname', lastname)
    formData.append('password', password)
    formData.append('idclub', idclub)
    formData.append('file', cardimage)
    formData.append('cedula', cedula)
    formData.append('phone', phone)
    let birth_date = String(year + "-" + month + "-" + day)
    formData.append('birth_date', birth_date)

    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    };
    const { data } = await axios.post(
        '/api/users/register/',
        formData,
        config
    );

    dispatch(slice.actions.loginSucces(data))

    localStorage.setItem('userInfo', JSON.stringify(data));

    return data;

};

export const getUserReservations = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/getReservations/',
        config
    );

    dispatch(slice.actions.getUserReservations(data));

    return data
};

export const getDefaulting = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/getDefaulting/',
        config
    );

    dispatch(slice.actions.getDefaulting(data));
};

export const getSuspensionStatus = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/getSuspensionStatus/',
        config
    );

    dispatch(slice.actions.getSuspensionStatus(data));
};

export const approvalCheck = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/approvalCheck/',
        config
    );

    dispatch(slice.actions.approvalCheck(data));
};

export const resetPassword = (email) => async (dispatch) => {

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
    const { data } = await axios.post(
        '/auth/users/reset_password/',
        { 'email': email },
        config
    );

    dispatch(slice.actions.resetPassword());

};

export const resetPasswordConfirm = (uid, token, new_password, re_new_password) => async (dispatch) => {

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
    const { data } = await axios.post(
        '/auth/users/reset_password_confirm/',
        { 'uid': uid, 'token': token, 'new_password': new_password },
        config
    );

    dispatch(slice.actions.resetPasswordConfirm());

};

export const updateUserProfile = (data) => async (dispatch) => {
    dispatch(slice.actions.updateUserProfile(data));
    localStorage.setItem('userInfo', JSON.stringify(data));
};

export const getFriends = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/getFriends/',
        config
    );

    dispatch(slice.actions.getFriends(data));
};

export const getUserAge = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/user/age/',
        config
    );

    dispatch(slice.actions.getUserAge(data));

    return data
};

export const updateAvatar = (image) => async (dispatch) => {

    var existing = localStorage.getItem('userInfo');
    existing = existing ? JSON.parse(existing) : {};
    existing['avatar'] = image;
    localStorage.setItem('userInfo', JSON.stringify(existing));

    dispatch(slice.actions.updateAvatar(image));
};


export default slice;
