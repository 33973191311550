import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { faFacebookF, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Logo from './LogoWhite';
import MinusIcon from '../icons/Minus';

const sections = [
  {
    title: 'Menu',
    links: [
      {
        title: 'Inicio',
        href: '/'
      },
      {
        title: 'Historia',
        href: '/miclub/historia'
      },
      {
        title: 'Junta Directiva y Admisión',
        href: '/miclub/junta-directiva-y-admision'
      },
      {
        title: 'Reciprocidad',
        href: '/miclub/reciprocidade'
      },
      /* {
         title: 'Información de Contacto',
         href: '#'
       }*/
    ]
  },
  {
    title: 'Placeholders',
    links: [
      {
        title: 'Restaurantes',
        href: '/restaurantes'
      },
      {
        title: 'Deportes',
        href: '/deportes/areas-deportivas'
      },
      /*{
        title: 'Concesionarios',
        href: '#'
      },*/
      {
        title: 'Banquetes',
        href: '/banquetes'
      },
      {
        title: 'Revista Elite',
        href: '/revista-elite'
      },/*
      {
        title: 'Actividades',
        href: '/actividades'
      }*/
    ]
  },
  {
    title: 'Social',
    links: [
      {
        title: 'Actividades',
        href: '/actividades'
      },
      {
        title: 'Contacto',
        href: '/contacto'
      },
      {
        title: 'Buzón de sugerencias',
        href: '/miclub/buzon-de-sugerencias'
      },
      {
        title: 'Términos y Condiciones',
        href: '/terminos-y-condiciones'
      },/*
      {
        title: 'Política de privacidad',
        href: '/politica-de-privacidad'
      }*/
    ]
  }
];

const today = new Date();

const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: 'background.primary',
      pb: 6,
      pt: {
        md: 15,
        xs: 6
      }
    }}
    {...props}
  >
    <Container maxWidth="lg" className="cu-footer">
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          sm={12}
          sx={{
            order: {
              md: 1,
              xs: 4
            },
            textAlign: {
              md: 'left',
              xs: 'center'
            },
          }}
          xs={12}
        >
          <Logo sx={{ display: 'inline-block' }} />

          <Typography
            color="text.light"
            sx={{ pt: 2, display: 'block' }}
            variant="caption"
          >
            <a href='https://goo.gl/maps/4TWFkXf8gqf1L4u88' target='_black'>Dirección: C. Tomas Gabriel Duque, Panamá.</a><br />
            info@club-union.com<br />
            <a href="tel:+5072085300">+507 208-5300</a>
          </Typography>
          <Box>
            <Box
              className="cu-sociallist"
            >
              <a href='https://www.instagram.com/clubunionpa/' target='_blank' rel="noreferrer"><FontAwesomeIcon style={{ color: "white" }} icon={faInstagram} size="1x" /></a>
              <a href='https://www.facebook.com/clubuniondepanama' target='_blank' rel="noreferrer"><FontAwesomeIcon style={{ color: "white" }} icon={faFacebookF} size="1x" /></a>
              <a href='https://wa.me/+50763785076?text=Hola!' target='_blank' rel="noreferrer"><FontAwesomeIcon style={{ color: "white" }} icon={faWhatsapp} size="1x" /></a>
            </Box>
          </Box>

        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1
              },
              display: {
                md: 'block',
                xs: 'none'
              }
            }}
            xs={12}
          >
            <Typography
              color="text.light"
              variant="overline"
            >

            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: 0,
                      mr: 0.5
                    }}
                  >
                    <MinusIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Link
                        to={link.href}
                        color="text.light"
                        variant="subtitle2"
                      >
                        {link.title}
                      </Link>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Box sx={{
        textAlign: {
          md: 'center',
          xs: 'center'
        },
        pt: {
          md: 0,
          xs: 4
        }
      }} className='logo-iac'><img src="/static/images/logo-full.svg" ></img></Box>
      <Divider
        sx={{
          borderColor: 'text.blue',
          opacity: 0.3,
          my: 3
        }}
      />
      <Typography
        color="text.blue"
        variant="caption"
        sx={{ display: 'block', textAlign: 'center' }}
      >
        © 1917-{today.getFullYear()} Club Unión de Panamá. Todos los derechos reservados.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
