import { useDispatch, useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ClientAdminGuard = ({ children }) => {
  const authState = useSelector(state => state.userLogin)

  const { userInfo } = authState

  const allowlist = [
    'SuperAdmin',
    'Admin',
    'Socio',
  ]
  if ((allowlist.indexOf(userInfo.role) > -1) == false) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

ClientAdminGuard.propTypes = {
  children: PropTypes.node
};

export default ClientAdminGuard;
