import * as React from "react";

import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  Toolbar,
} from "@material-ui/core";

import Approval from "./Approval";
import Communique from "./Communique";
import CuNav from "./CuNav";
import Defaulter from "./Defaulter";
import Logo from "./Logo";
import MenuIcon from "../icons/Menu";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  padding: 0,
  paddingRight: 0,
  // Override media queries injected by theme.mixins.toolbar
}));

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  const useStyles = makeStyles((theme) => ({
    menu1: {
      "& svg": {
        fontSize: 33,
      },
    },
  }));

  const classes = useStyles();

  return (
    <AppBar
      className={"cu-navmain"}
      elevation={0}
      position="fixed"
      style={{ boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.05)" }}
      sx={{
        backgroundColor: "background.paper",
        color: "text.primary",
      }}
    >
      <StyledToolbar>
        <Container maxWidth="lg">
          <Toolbar sx={{ minHeight: 64 }}>
            <IconButton
              disableFocusRipple
              className={classes.menu1}
              color="inherit"
              onClick={onSidebarMobileOpen}
              sx={{
                display: {
                  md: "none",
                },
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>

            <Box className="logo-navbar">
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "end",
                alignItems: "center",
                display: {
                  md: "flex",
                  xs: "none",
                },
              }}
            >
              <CuNav />
            </Box>
          </Toolbar>
        </Container>
        <Divider light={true} />
      </StyledToolbar>
      <Defaulter />
      <Approval />
      <Communique />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
