import './i18n';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { getFriends, getSuspensionStatus } from './slices/user';
import { useDispatch, useSelector } from 'react-redux';

import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { Toaster } from 'react-hot-toast';
import { createCustomTheme } from './theme';
import { getRestaurants } from './slices/type';
import gtm from './lib/gtm';
import { gtmConfig } from './config';
import routes from './routes';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';

const App = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes);
  const { isInitialized, isAuthenticated, userInfo } = useSelector(state => state.userLogin);
  const { restInitialized } = useSelector((state) => state.type);
  const { settings } = useSettings();

  var style = "LIGHT"

  if (window.location.href.indexOf("dashboard") !== -1) {
    style = "DARK";
  }

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);

    if (!restInitialized) {
      dispatch(getRestaurants());
    }

    if (isAuthenticated && userInfo.role == "Socio") {
      dispatch(getSuspensionStatus(userInfo.token));
      dispatch(getFriends(userInfo.token))
    }

  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: style,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {isInitialized ? content : <SplashScreen />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
