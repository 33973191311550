import { authApi } from '../__fakeApi__/authApi';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    magInitialized: false,
    magLoading: true,
    magazines: [],
    current: [],
    edition: [],
};


const slice = createSlice({
    name: 'magazine',
    initialState,
    reducers: {
        magazinesRequest(state, action) {
            state.magLoading = true;
        },
        getMagazines(state, action) {
            state.magInitialized = true;
            state.magazines = action.payload.mags;
            state.magLoading = false;
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        getMagazineCurrent(state, action) {
            state.current = action.payload
        },
        getMagazine(state, action) {
            state.magLoading = false;
            state.edition = action.payload
        },
    }
});

export const { reducer } = slice;

export const magazinesRequest = () => async (dispatch) => {
    dispatch(slice.actions.magazinesRequest());
};

export const getMagazinecurrent = () => async (dispatch) => {


    const { data } = await axios.get('/api/magazine/')

    dispatch(slice.actions.getMagazineCurrent(data));

};

export const getMagazines = (location) => async (dispatch) => {

    const { data } = await axios.get(`/api/magazines/${location}`)

    dispatch(slice.actions.getMagazines(data));
};

export const getMagazine = (slug) => async (dispatch) => {

    const { data } = await axios.get(`/api/magazine_edition/${slug}`)

    dispatch(slice.actions.getMagazine(data));
};

export default slice;
