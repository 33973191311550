import {
    Box,
    Button,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import LoadingButton from '@mui/lab/LoadingButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Stack from '@mui/material/Stack';
import axios from 'axios';

const NotificationCancelation = (props) => {
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const { userInfo } = useSelector((state) => state.userLogin);
    const [ notificcationFound, setNotificcationFound ] = useState(false);

    const updateStatus = (response) => {

        if (response.data.detail == "found") {
            setNotificcationFound(true)
        } else if (response.data.detail === "notfound") {
            setNotificcationFound(false)
        } else if (response.data.detail === "deleted") {
            setNotificcationFound(false)
        } else if (response.data.detail === "added") {
            setNotificcationFound(true)
        } else {
            setNotificcationFound(false)
        }
    }

    const notification = async () => {

        setLoading(true)
        await axios({
            method: "POST",
            url: "/api/reservation/cancelNotification/",
            data: { reservation: props.resid, user: userInfo.user_id, action: 'check' },
            headers: {
              'Authorization': `Bearer ${userInfo.token}`
            }
          })
            .then(response => {
                setDisabled(response.data.active == true ? false : true)
                if (response.data.unknow == userInfo.user_id) {
                    setDisabled(true)
                }
                updateStatus(response)
                setLoading(false)
            })
            .catch(error => {
              console.log(error)
              setDisabled(true)
              setNotificcationFound(false)
              setLoading(false)
            });
    }

    const addRemoveNotification = async () => {

        setLoading(true)
        await axios({
            method: "POST",
            url: "/api/reservation/cancelNotification/",
            data: { reservation: props.resid, user: userInfo.user_id, status: notificcationFound, action: 'add' },
            headers: {
              'Authorization': `Bearer ${userInfo.token}`
            }
          })
            .then(response => {
                updateStatus(response)
                setLoading(false)
            })
            .catch(error => {
              console.log(error)
              setLoading(false)
            });
    }

    useEffect(() => {
        notification()
    }, []);

    return (
        <>
            <Box sx={{ pt: 2 }}>
                <Typography id="modal-cancelation" component="h2" sx={{ fontSize: 17, fontWeight: 'bold' }}>
                    Notificación en caso de cancelación <CircleIcon color={notificcationFound === false ? "action" : "success"} sx={{ fontSize: 10 }} />
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="body2"
                    sx={{
                        textAlign: {
                            md: 'justify',
                            xs: 'justify'
                        }
                    }}
                >
                    Estimado socio, al activar la opción de notificación en caso de cancelación de esta reserva en específico, usted recibirá un correo electrónico notificándole de que la reserva ha sido liberada.
                </Typography>
                <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                    <LoadingButton disabled={disabled} onClick={(e) => addRemoveNotification()} loading={loading} variant="contained" sx={{
                        backgroundColor: notificcationFound == true ? '#124256 !important' : '#2383AC',
                    }} endIcon={<NotificationsActiveIcon />}>
                        {notificcationFound === false ? "Notificarme" : "Desactivar"}
                    </LoadingButton>
                </Stack>
            </Box>
        </>
    )
}

export default NotificationCancelation;