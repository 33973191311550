import { useDispatch, useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AdminGuard = ({ children }) => {
  const authState = useSelector(state => state.userLogin)

  const { isAuthenticated, userInfo } = authState

  const allowlist = [
    'SuperAdmin',
    'Admin',
  ]
  if ((allowlist.indexOf(userInfo.role) > -1) == false) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
