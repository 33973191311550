import { reducer as blogReducer } from '../slices/blog';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as contestReducer } from '../slices/contest';
import { reducer as dashboard } from '../slices/dashboard';
import { reducer as eventReducer } from '../slices/event';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as magazineReducer } from '../slices/magazine';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as typeReducer } from '../slices/type';
import { reducer as userReducer } from '../slices/user';

const rootReducer = combineReducers({
  userLogin: userReducer,
  type: typeReducer,
  dashboard: dashboard,
  magazine: magazineReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  blog: blogReducer,
  contest: contestReducer,
  event: eventReducer,
});

export default rootReducer;
