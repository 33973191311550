import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import { configureStore } from '@reduxjs/toolkit';
import devToolsEnhancer from 'remote-redux-devtools'
import logger from 'redux-logger'
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
