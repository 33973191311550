import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

const ListDays = ({ start, end }) => {

    const studentDetails = ['Alex', 'Anik', 'Deven', 'Rathore'];

    const names = []

    studentDetails.forEach((data) => {
        names.push(<h3 className='student_name'>{data}</h3>)
    })

    return (
        <>
            <ListItem>
                <ListItemText primary="Inbox" />
            </ListItem>
            <Divider />
        </>
    )
}

export default ListDays;
