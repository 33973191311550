import { useDispatch, useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const authState = useSelector(state => state.userLogin)

  const { isAuthenticated } = authState

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
