import * as Yup from 'yup';

import { Alert, Box, Button, FormHelperText, TextField } from '@material-ui/core';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../store';

import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { userLogin } from '../../../slices/user';

const LoginJWT = (props) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const location = useLocation();
  const navigate = useNavigate();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        password2: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Debe ser un correo electrónico válido.')
            .max(255)
            .required('El correo electrónico es requerido.'),
          password: Yup
            .string()
            .max(255)
            .required('Se requiere su contraseña.')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let data = await dispatch(userLogin(values.email, values.password));

          if (data.role === "Counter") {
            navigate('/counter')
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            inputProps={{ style: inputStyle }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            inputProps={{ style: inputStyle }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <LoadingButton
                color="primary"
                size="large"
                fullWidth
                type="submit"
                loading={isSubmitting}
                loadingPosition="center"
                variant="contained"
              >
                Iniciar sesión
              </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
