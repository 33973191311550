import { Suspense, lazy } from "react";

import AdminGuard from "./components/AdminiGuard";
import AuthGuard from "./components/AuthGuard";
import BrowseLayout from "./components/BrowseLayout";
import ClientAdminGuard from "./components/ClientAdminGuard";
import CounterGuard from "./components/CounterGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import DocsLayout from "./components/docs/DocsLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import { Navigate } from "react-router-dom";
import PicklballSportsForm from "./components/forms/PicklballSportsForm";
import ViewerGuard from "./components/ViewerGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

const Browse = Loadable(lazy(() => import("./pages/browse/Browse")));
const BrowseButtons = Loadable(lazy(() => import("./pages/browse/BrowseButtons")));
const BrowseCharts = Loadable(lazy(() => import("./pages/browse/BrowseCharts")));
const BrowseColors = Loadable(lazy(() => import("./pages/browse/BrowseColors")));
const BrowseDetailLists = Loadable(lazy(() => import("./pages/browse/BrowseDetailLists")));
const BrowseForms = Loadable(lazy(() => import("./pages/browse/BrowseForms")));
const BrowseGridLists = Loadable(lazy(() => import("./pages/browse/BrowseGridLists")));
const BrowseGroupedLists = Loadable(lazy(() => import("./pages/browse/BrowseGroupedLists")));
const BrowseInputs = Loadable(lazy(() => import("./pages/browse/BrowseInputs")));
const BrowseModals = Loadable(lazy(() => import("./pages/browse/BrowseModals")));
const BrowseQuickStats = Loadable(lazy(() => import("./pages/browse/BrowseQuickStats")));
const BrowseTables = Loadable(lazy(() => import("./pages/browse/BrowseTables")));
const BrowseTypography = Loadable(lazy(() => import("./pages/browse/BrowseTypography")));

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const LogOut = Loadable(lazy(() => import("./pages/authentication/LogOut")));
const PasswordRecovery = Loadable(lazy(() => import("./pages/authentication/PasswordRecovery")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/PasswordReset")));
const Register = Loadable(lazy(() => import("./pages/authentication/Register")));
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/VerifyCode")));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import("./pages/blog/BlogPostCreate")));
const BlogPostDetails = Loadable(lazy(() => import("./pages/blog/BlogPostDetails")));
const BlogPostList = Loadable(lazy(() => import("./pages/blog/BlogPostList")));

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));
const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
const Chat = Loadable(lazy(() => import("./pages/dashboard/Chat")));
const CustomerDetails = Loadable(lazy(() => import("./pages/dashboard/CustomerDetails")));

const UserDetails = Loadable(lazy(() => import("./pages/dashboard/UserDetails")));

const ReservationList = Loadable(lazy(() => import("./pages/dashboard/ReservationList")));

const EventList = Loadable(lazy(() => import("./pages/dashboard/EventList")));

const CustomerEdit = Loadable(lazy(() => import("./pages/dashboard/CustomerEdit")));
const CustomerList = Loadable(lazy(() => import("./pages/dashboard/CustomerList")));
const SociosList = Loadable(lazy(() => import("./pages/dashboard/SociosList")));
const SuspensionList = Loadable(lazy(() => import("./pages/dashboard/SuspensionList")));

const AdminList = Loadable(lazy(() => import("./pages/dashboard/AdminList")));
const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));
const InvoiceDetails = Loadable(lazy(() => import("./pages/dashboard/InvoiceDetails")));
const InvoiceList = Loadable(lazy(() => import("./pages/dashboard/InvoiceList")));
const Kanban = Loadable(lazy(() => import("./pages/dashboard/Kanban")));
const Mail = Loadable(lazy(() => import("./pages/dashboard/Mail")));
const OrderDetails = Loadable(lazy(() => import("./pages/dashboard/OrderDetails")));
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const ProductCreate = Loadable(lazy(() => import("./pages/dashboard/ProductCreate")));
const ProductList = Loadable(lazy(() => import("./pages/dashboard/ProductList")));

// Docs pages

const Docs = Loadable(lazy(() => import("./pages/Docs")));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import("./pages/dashboard/ProjectBrowse")));
const ProjectCreate = Loadable(lazy(() => import("./pages/dashboard/ProjectCreate")));
const ProjectDetails = Loadable(lazy(() => import("./pages/dashboard/ProjectDetails")));

// Social pages

const SocialFeed = Loadable(lazy(() => import("./pages/dashboard/SocialFeed")));
const SocialProfile = Loadable(lazy(() => import("./pages/dashboard/SocialProfile")));

// Other pages

const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
const Contact = Loadable(lazy(() => import("./pages/Contact")));
const Contacto = Loadable(lazy(() => import("./pages/contacto/Contacto")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const Products = Loadable(lazy(() => import("./pages/Products")));
const Pricing = Loadable(lazy(() => import("./pages/Pricing")));
const Theming = Loadable(lazy(() => import("./pages/Theming")));

// Mi club
const Historia = Loadable(lazy(() => import("./pages/miclub/historia")));
const Junta = Loadable(lazy(() => import("./pages/miclub/junta")));
const Reciprocidade = Loadable(lazy(() => import("./pages/miclub/reciprocidade")));
const Icontacto = Loadable(lazy(() => import("./pages/miclub/icontacto")));
const Bsugerencias = Loadable(lazy(() => import("./pages/miclub/bsugerencias")));
const Terminos = Loadable(lazy(() => import("./pages/miclub/terminosycondiciones")));
const Estatutos = Loadable(lazy(() => import("./pages/miclub/estatutos")));


// Restaurantes
const Restaurantes = Loadable(lazy(() => import("./pages/restaurantes/Restaurantes")));
const RestauranteContent = Loadable(lazy(() => import("./pages/restaurantes/RestauranteContent")));

// Deportes
const ClasesDeportivas = Loadable(lazy(() => import("./pages/deportes/clases-deportivas")));
const ClaseDeportiva = Loadable(lazy(() => import("./pages/deportes/claseDeportiva")));
const AreasDeportivas = Loadable(lazy(() => import("./pages/deportes/AreasDeportivas")));
const AreaDeportiva = Loadable(lazy(() => import("./pages/deportes/AreaDeportiva")));
const KidsClub = Loadable(lazy(() => import("./pages/deportes/KidsClub")));

// Concesionarios
const Concesionarios = Loadable(lazy(() => import("./pages/concesionarios/Concesionarios")));
const Concesionario = Loadable(lazy(() => import("./pages/concesionarios/Concesionario")));

// Banquetes
const Banquetes = Loadable(lazy(() => import("./pages/banquetes/Banquetes")));
const Banquete = Loadable(lazy(() => import("./pages/banquetes/Banquete")));

// Revista Elite
const RevistaElite = Loadable(lazy(() => import("./pages/revistaelite/RevistaElite")));
const RevistaEdicion = Loadable(lazy(() => import("./pages/revistaelite/RevistaEdicion")));

const Calendario = Loadable(lazy(() => import("./pages/actividades/Calendario")));

// Mi Cuenta
const Cuenta = Loadable(lazy(() => import("./pages/cuenta/Cuenta")));
const Reserva = Loadable(lazy(() => import("./pages/cuenta/Reserva")));

//caunter
const Counter = Loadable(lazy(() => import("./pages/counter/counter")));
const CounterReservas = Loadable(lazy(() => import("./pages/counter/reservas")));
const ViewerReservas = Loadable(lazy(() => import("./pages/viewer/Viewer")));

const ReservaAdmin = Loadable(lazy(() => import("./pages/dashboard/ReservaAdmin")));

const ReservaCounter = Loadable(lazy(() => import("./pages/counter/ReservaCounter")));
const ReservaViewer = Loadable(lazy(() => import("./pages/viewer/ReservaViewer")));

// Events

const MarDelSur = Loadable(lazy(() => import("./pages/event/mardelsur")));

const Event = Loadable(lazy(() => import("./pages/event/event")));

// Contest

const Contest = Loadable(lazy(() => import("./pages/contest/contest")));
const ValidacionQR = Loadable(lazy(() => import("./pages/ValidacionQR")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "logout",
        element: <LogOut />,
      },
      {
        path: "password-recovery",
        element: (
          <MainLayout>
            <PasswordRecovery />
          </MainLayout>
        ),
      },
      {
        path: "password-reset/:uid/:token",
        element: (
          <MainLayout>
            <PasswordReset />
          </MainLayout>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <MainLayout>
              <Register />
            </MainLayout>
          </GuestGuard>
        ),
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: "counter",
    element: (
      <AuthGuard>
        <CounterGuard>
          <MainLayout />
        </CounterGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Counter />,
      },
      {
        path: "/deportes",
        element: <Counter />,
      },
      {
        path: "/reservas-deportivas",
        element: <CounterReservas />,
      },
      {
        path: "reserva-deportiva/:resid",
        element: <ReservaCounter />,
      },
    ],
  },
  {
    path: "viewer",
    element: (
      <AuthGuard>
        <ViewerGuard>
          <MainLayout />
        </ViewerGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "/reservas",
        element: <ViewerReservas />,
      },
      {
        path: "reserva/:resid",
        element: <ReservaViewer />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
      {
        path: "chat",
        children: [
          {
            path: "/",
            element: <Chat />,
          },
          {
            path: "new",
            element: <Chat />,
          },
          {
            path: ":threadKey",
            element: <Chat />,
          },
        ],
      },
      {
        path: "usuarios",
        children: [
          {
            path: "/",
            element: <CustomerList />,
          },
          {
            path: "/socios",
            element: <SociosList />,
          },
          {
            path: "/suspensiones",
            element: <SuspensionList />,
          },
          {
            path: "/administradores",
            element: <AdminList />,
          },
          {
            path: "/usuario/:userId/edit",
            element: <CustomerEdit />,
          },
          {
            path: "/usuario/:userId",
            element: <UserDetails />,
          },
        ],
      },
      {
        path: "reservaciones",
        children: [
          {
            path: "/",
            element: <NotFound />,
          },
          {
            path: "/deportes",
            element: <ReservationList />,
          },
          {
            path: "/deportes/reservacion/:resId/edit",
            element: <CustomerEdit />,
          },
          {
            path: "/deportes/reservacion/:resId",
            element: <ReservaAdmin />,
          },
          {
            path: "/eventos",
            element: <EventList />,
          },
          {
            path: "/evento/:eventId",
            element: <ReservaAdmin />,
          },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            path: "/",
            element: <InvoiceList />,
          },
          {
            path: ":invoiceId",
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: "kanban",
        element: <Kanban />,
      },
      {
        path: "mail",
        children: [
          {
            path: "/",
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
          {
            path: "label/:customLabel",
            element: <Mail />,
          },
          {
            path: "label/:customLabel/:emailId",
            element: <Mail />,
          },
          {
            path: ":systemLabel",
            element: <Mail />,
          },
          {
            path: ":systemLabel/:emailId",
            element: <Mail />,
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "/",
            element: <OrderList />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "finance",
        element: <Finance />,
      },
      {
        path: "products",
        children: [
          {
            path: "/",
            element: <ProductList />,
          },
          {
            path: "new",
            element: <ProductCreate />,
          },
        ],
      },
      {
        path: "projects",
        children: [
          {
            path: "browse",
            element: <ProjectBrowse />,
          },
          {
            path: "new",
            element: <ProjectCreate />,
          },
          {
            path: ":projectId",
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeed />,
          },
          {
            path: "profile",
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },

  {
    path: "docs",
    element: <DocsLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: "*",
        element: <Docs />,
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },/*
      {
        path: "browse",
        element: <BrowseLayout />,
        children: [
          {
            path: "/",
            element: <Browse />,
          },
          {
            path: "/buttons",
            element: <BrowseButtons />,
          },
          {
            path: "/inputs",
            element: <BrowseInputs />,
          },
          {
            path: "/charts",
            element: <BrowseCharts />,
          },
          {
            path: "/colors",
            element: <BrowseColors />,
          },
          {
            path: "/data-display/detail-lists",
            element: <BrowseDetailLists />,
          },
          {
            path: "/data-display/quick-stats",
            element: <BrowseQuickStats />,
          },
          {
            path: "/data-display/tables",
            element: <BrowseTables />,
          },
          {
            path: "/forms",
            element: <BrowseForms />,
          },
          {
            path: "/modals",
            element: <BrowseModals />,
          },
          {
            path: "/lists/grouped-lists",
            element: <BrowseGroupedLists />,
          },
          {
            path: "/lists/grid-lists",
            element: <BrowseGridLists />,
          },
          {
            path: "/typography",
            element: <BrowseTypography />,
          },
        ],
      },*/
      {
        path: "/validacion-de-codigo-qr",
        element: <ValidacionQR />,
      },
      {
        path: "/terminos-y-condiciones",
        element: <Terminos />,
      },
      {
        path: "/miclub/historia",
        element: <Historia />,
      },
      {
        path: "/miclub/junta-directiva-y-admision",
        element: <Junta />,
      },
      {
        path: "/miclub/reciprocidade",
        element: <Reciprocidade />,
      },
      {
        path: "/miclub/informacion-de-contacto",
        element: <Icontacto />,
      },
      {
        path: "/miclub/buzon-de-sugerencias",
        element: <Bsugerencias />,
      },
      {
        path: "/miclub/estatutos-y-pacto-social",
        element: <Estatutos />
      },
      {
        path: "contacto",
        element: <Contacto />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "deportes/clases-deportivas",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <ClasesDeportivas />,
      },
      {
        path: ":slug",
        element: <ClaseDeportiva />,
      },
    ],
  },
  {
    path: "deportes/areas-deportivas",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <AreasDeportivas />,
      },
      {
        path: ":slug",
        element: <AreaDeportiva />,
      },
    ],
  },
  {
    path: "deportes/kids-club",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <KidsClub title="Kids Club Unión" />,
      },
    ],
  },
  {
    path: "concesionarios",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Concesionarios />,
      },
      {
        path: ":slug",
        element: <Concesionario />,
      },
    ],
  },
  {
    path: "banquetes",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Banquetes />,
      },
      {
        path: ":slug",
        element: <Banquete />,
      },
    ],
  },
  {
    path: "revista-elite",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <RevistaElite />,
      },
      {
        path: ":slug",
        element: <RevistaEdicion />,
      },
    ],
  },
  {
    path: "actividades",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Calendario />,
      },
    ],
  },
  {
    path: "blog",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <BlogPostList />,
      },
      {
        path: ":slug",
        element: <BlogPostDetails />,
      },
    ],
  },
  {
    path: "restaurantes",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Restaurantes />,
      },
      {
        path: ":slug",
        element: <RestauranteContent />,
      },
    ],
  },
  {
    path: "contest",
    element: (
      /*<AuthGuard>*/
        <MainLayout />
     /* </AuthGuard>*/
    ),
    children: [
      {
        path: "/",
        element: <NotFound />,
      },
      {
        path: ":slug",
        element: <Contest />,
      },
    ],
  },
  {
    path: "cuenta",
    element: (
      <AuthGuard>
        <ClientAdminGuard>
          <MainLayout />
        </ClientAdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Cuenta />,
      },
      {
        path: "/:slug",
        element: <Cuenta />,
      },
      {
        path: "reserva/:resid",
        element: <Reserva />,
      },
    ],
  },
  {
    path: "eventos",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <NotFound />,
      },
      {
        path: ":prefix/:slug",
        element: <Event />,
      },
    ],
  },
];

export default routes;
