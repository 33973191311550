import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import './App.css';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HttpsRedirect from 'react-https-redirect';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { SettingsProvider } from './contexts/SettingsContext';
import { StrictMode } from 'react';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import store from './store';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration) => {
        // console.log('Service Worker registrado con éxito:', registration);
      })
      .catch((error) => {
        // console.error('Error al registrar el Service Worker:', error);
      });
  });
}

ReactDOM.render(
  <StrictMode>
    <HttpsRedirect>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <HashRouter>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </HashRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </HelmetProvider>
    </HttpsRedirect>
  </StrictMode>, document.getElementById('root')
);