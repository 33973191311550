import { createContext, useEffect, useState } from 'react';
import { isAuthenticated, isInitialized, userLogin as login, userLogout as logout, userRegister as register } from '../slices/user';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useReducer } from "reinspect";
import { userLogout } from '../slices/user';

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false;

const initialState = {
  isAuthenticated: userInfoFromStorage ? true : false,
  isInitialized: false,
  defaulting: false,
  approval: false,
  reservations:[],
  userInfo: userInfoFromStorage
};

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, setstate] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(userLogout());
      navigate('/authentication/login');
    } catch (err) {
      console.error(err);
    }
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const AuthVerify = () => {
    if(userInfoFromStorage != false) {
      const user = userInfoFromStorage
      if (user) {
        const decodedJwt = parseJwt(user.token);
        if (decodedJwt.exp * 1000 < Date.now()) {
          handleLogout()
        }
      }
    }
  };

  useEffect(() => {

    const initialize = async () => {
      try {
        dispatch(isInitialized())
      } catch (err) {
        console.error(err);
      }
    };

    initialize();
    AuthVerify();

  }, []);

  return (

    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        avatar: '',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
