import { useEffect, useState } from "react";

import moment from "moment";

const useLocalTime = () => {
  const [localTime, setLocalTime] = useState(null);

  useEffect(() => {
    const fetchLocalTime = async () => {
      const response = await fetch("/api/time/");
      const data = await response.json();
      const dateObject = moment(data.date, "YYYY-MM-DD");
      const timeObject = moment({
        hour: data.hour,
        minute: data.minute,
        second: data.second
      });
      const dateTimeObject = moment({
        year: dateObject.year(),
        month: dateObject.month(),
        date: dateObject.date(),
        hour: timeObject.hour(),
        minute: timeObject.minute(),
        second: timeObject.second()
      });
      const dateTimeString = dateTimeObject.format("YYYY-MM-DD HH:mm:ss");
      setLocalTime(dateTimeString);
    };
    fetchLocalTime();
  }, []);

  return localTime;
};

export default useLocalTime;