import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  categories: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
    },
    getEventsCategories(state, action) {
      state.categories = action.payload;
    },
    createEvent(state, action) {
      state.events.push(action.payload);
    },
    selectEvent(state, action) {
      state.isModalOpen = true;
      state.selectedEventId = action.payload;
    },
    updateEvent(state, action) {
      const event = action.payload;

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      state.events = state.events.filter((event) => event.id !== action.payload);
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    removeEventImage(state, action) {
      const event = action.payload.event;
      const images = action.payload.images;

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          const new_obj = { ..._event, event_images: images };
          return new_obj;
        }

        return _event;
      });
    },
  },
});

export const { reducer } = slice;

export const getEvents = () => async (dispatch) => {
  const { data } = await axios.get("/api/events/");

  dispatch(slice.actions.getEvents(data));
};

export const getEventsDashboard = () => async (dispatch) => {
  const { data } = await axios.get("/api/dashboard/events/");

  dispatch(slice.actions.getEvents(data));
};

export const getEventsCategories = () => async (dispatch) => {
  const { data } = await axios.get("/api/events/categories/");

  dispatch(slice.actions.getEventsCategories(data));
};

export const createEvent = (createData, token) => async (dispatch) => {
  await axios
    .post("/api/events/addEvent/", createData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        console.log(response.data);
        createData["id"] = response.data["eventID"];
        createData["color"] = response.data["color"];
        createData["event_images"] = [];

        if (response.data.event_images.length > 0) {
          var images = [];
          response.data.event_images.forEach((img) => {
            images.push({ id: img.id, name: img.name, order: img.order, event: Number(response.data["eventID"]) });
          });
          createData["event_images"] = images;
        }
        dispatch(slice.actions.createEvent(createData));
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent(eventId));
};

export const updateEvent = (eventId, update, token) => async (dispatch) => {
  await axios
    .post(
      "/api/events/updateEvent/",
      { id: eventId, data: update },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        update["id"] = eventId;
        update["color"] = response.data["color"];
        update["event_images"] = [];

        if (response.data.event_images.length > 0) {
          var images = [];
          response.data.event_images.forEach((img) => {
            images.push({ id: img.id, name: img.name, order: img.order, event: Number(eventId) });
          });
          update["event_images"] = images;
        }

        dispatch(slice.actions.updateEvent(update));
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const moveEvent = (eventId, update, token) => async (dispatch) => {
  await axios
    .post(
      "/api/events/moveEvent/",
      { id: eventId, data: update },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        // dispatch(slice.actions.updateEvent(update));
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const deleteEvent = (eventId, token) => async (dispatch) => {
  await axios
    .delete(`/api/events/deleteEvent/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(slice.actions.deleteEvent(eventId));
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const removeEventImage = (event, file, token) => async (dispatch) => {
  await axios
    .post(
      "/api/events/removeEventImage/",
      { event: event, data: file },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        var data = { event: event, images: response.data.event_images };
        dispatch(slice.actions.removeEventImage(data));
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
