import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'react-hot-toast';

const initialState = {
    contestInitialized: false,
    userVote: false,
    userChoice: "",
    winner: "Ninguno",
    contest: [],
};

const slice = createSlice({
    name: 'contest',
    initialState,
    reducers: {
        getContest(state, action) {
            state.contest = action.payload[0];
            
            if (action.payload.length > 0) {
                state.contestInitialized = true;
            }
        },
        voteUpdate(state, action) {
            const { data, choice } = action.payload;

            data.forEach((com) => {
                const competitor = state.contest.contest_competitors.find(
                    (item) => item.id === com.id
                  );

                  if (competitor) {
                    competitor.votes = com.votes;
                  }
            });

            state.userVote = true;
            state.contest.voted = true;
            state.userChoice = choice;

        },
        updateUserVote(state, action) {
            state.userVote = action.payload;
        },
        updateUserChoice(state, action) {
            state.userChoice = action.payload
        },
        voteUpdating(state, action) {
            const { data, finalized, winner } = action.payload;

            data.forEach((com) => {
                const competitor = state.contest.contest_competitors.find(
                    (item) => item.id === com.id
                  );

                  if (competitor) {
                    competitor.votes = com.votes;
                  }
            });

            state.contest.finalized = finalized
            state.winner = winner
        }
    }
});

export const { reducer } = slice;

export const getContest = (slug) => async (dispatch) => {

    const { data } = await axios.get(
        `/api/contest/${slug}`,
    );

    dispatch(slice.actions.getContest(data));

    return data
};

// export const getContest = (slug, token) => async (dispatch) => {

//     const config = {
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     };

//     const { data } = await axios.get(
//         `/api/contest/${slug}`,
//         config
//     );

//     dispatch(slice.actions.getContest(data));

//     return data
// };

export const putVote = (competitor, contest, token) => async (dispatch) => {
        
        const { data } = await axios.post(
            '/api/contest/vote/',
            { competitor: competitor, contest: contest },
        );

        dispatch(slice.actions.voteUpdate(data));

        return data

};

export const voteUpdating = (contest, token) => async (dispatch) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    
    const { data } = await axios.post(
        '/api/contest/voteUpdating/',
        { contest: contest },
        config
    );

    dispatch(slice.actions.voteUpdating(data));

};

export const updateUserVote = (vote, token, contest) => async (dispatch) => {

    if (vote) {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        
        const { data } = await axios.post(
            '/api/contest/voteuserChoice/',
            { contest: contest },
            config
        );
        dispatch(slice.actions.updateUserChoice(data.choice))
    }
    dispatch(slice.actions.updateUserVote(vote));
}

export default slice;