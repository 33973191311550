import { Avatar, Box, Button, Divider, Drawer, Link, Typography } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import Logo from '../Logo';
import MailIcon from '../../icons/Mail';
import NavSection from '../NavSection';
import PropTypes from 'prop-types';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Scrollbar from '../Scrollbar';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const sections = [
  {
    title: 'General',
    admin: 0,
    items: [
      {
        title: 'Sitio Web',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />,
        admin: 0
      },
      {
        title: 'Visión General',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
        admin: 1
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />,
        admin: 1
      },/*
      {
        title: 'Finance',
        path: '/dashboard/finance',
        icon: <ShoppingBagIcon fontSize="small" />
      },*/
      {
        title: 'Mi Cuenta',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
        admin: 1
      }
    ]
  },
  {
    title: 'Administración',
    admin: 0,
    items: [
      {
        title: 'Reservaciones',
        path: '/dashboard/reservaciones',
        admin: 0,
        icon: <CalendarTodayIcon fontSize="small" />,
        children: [
          {
            title: 'Deportes',
            path: '/dashboard/reservaciones/deportes',
            admin: 0
          },
          {
            title: 'Eventos',
            path: '/dashboard/reservaciones/eventos',
            admin: 0
          }
        ]
      },
      {
        title: 'Usuarios',
        admin: 0,
        path: '/dashboard/usuarios',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Socios',
            path: '/dashboard/usuarios/socios',
            admin: 0
          },
          {
            title: 'Suspensiones',
            path: '/dashboard/usuarios/suspensiones',
            admin: 0
          },
          {
            title: 'Administradores',
            path: '/dashboard/usuarios/administradores',
            admin: 1
          }
        ]
      },/*
      {
        title: 'Products',
        admin: 0,
        path: '/dashboard/products',
        icon: <ShoppingCartIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/products',
            admin: 0
          },
          {
            title: 'Create',
            path: '/dashboard/products/new',
            admin: 0
          }
        ]
      },
      {
        title: 'Orders',
        admin: 0,
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/orders',
        children: [
          {
            title: 'List',
            path: '/dashboard/orders',
            admin: 0
          },
          {
            title: 'Details',
            path: '/dashboard/orders/1',
            admin: 0
          }
        ]
      },
      {
        title: 'Sugerencias',
        admin: 0,
        path: '/dashboard/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/invoices',
            admin: 0
          },
          {
            title: 'Details',
            path: '/dashboard/invoices/1',
            admin: 0
          }
        ]
      },*/
      {
        title: 'Calendario',
        path: '/dashboard/calendar',
        icon: <CalendarIcon fontSize="small" />,
        admin: 0
      }
    ]
  },/*
  {
    title: 'Platforms',
    items: [
      {
        title: 'Projects',
        path: '/dashboard/projects',
        icon: <BriefcaseIcon fontSize="small" />,
        children: [
          {
            title: 'Browse',
            path: '/dashboard/projects/browse'
          },
          {
            title: 'Details',
            path: '/dashboard/projects/1'
          },
          {
            title: 'Create',
            path: '/dashboard/projects/new'
          }
        ]
      },
      {
        title: 'Social',
        path: '/dashboard/social',
        icon: <ShareIcon fontSize="small" />,
        children: [
          {
            title: 'Profile',
            path: '/dashboard/social/profile'
          },
          {
            title: 'Feed',
            path: '/dashboard/social/feed'
          }
        ]
      }
    ]
  },
  {
    title: 'Información',
    items: [
      {
        title: 'Kanban',
        path: '/dashboard/kanban',
        icon: <ClipboardListIcon fontSize="small" />,
        admin: 0
      },
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />,
        admin: 0
      },
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: <ChatAltIcon fontSize="small" />,
        admin: 0
      },
      {
        title: 'Calendario',
        path: '/dashboard/calendar',
        icon: <CalendarIcon fontSize="small" />,
        admin: 0
      }
    ]
  }*/
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { userInfo } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
