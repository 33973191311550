import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { getDefaulting } from '../slices/user';

function Defaulter() {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true);
    const { defaulting, isAuthenticated, userInfo } = useSelector((state) => state.userLogin);

    useEffect(() => {
        if (isAuthenticated) {
            if (userInfo.role == "Socio") {
                dispatch(getDefaulting(userInfo.token))
            }
        }
    }, []);

    return (
        <>
            {isAuthenticated && defaulting && (
                <Collapse in={open} sx={{ width: '100%', p: open === false ? '0px !important' : '' }} className="defaulter">
                    Estimado Socio, le informamos que mantiene un saldo pendiente a pagar. Por favor comunicarse con el departamento de Cobros, +507 208-5300.
                    <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Collapse>
            )}
        </>
    )
}

export default Defaulter;