import { authApi } from '../__fakeApi__/authApi';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    restInitialized: false,
    tablesInitialized: false,
    tablesLoading: false,
    type: [],
    tables: [{
        id: 1,
        name: "Cargando..."
    }]
};

const slice = createSlice({
    name: 'type',
    initialState,
    reducers: {
        restInitialized(state) {
            state.restInitialized = true;
        },
        getRestaurants(state, action) {
            state.restInitialized = true;
            state.type = action.payload
        },
        getTables(state, action) {
            state.tablesInitialized = true;
            state.tables = action.payload
            state.tablesLoading = false;
        },
        getTablesIni(state, action) {
            state.tablesLoading = true;
            state.tables = [{
                id: 1,
                name: "Cargando..."
            }]
        },
    }
});

export const { reducer } = slice;

export const getRestaurants = () => async (dispatch) => {
    const { data } = await axios.get('/api/restaurants/')
    dispatch(slice.actions.getRestaurants(data));
};

export const getTables = (type) => async (dispatch) => {
    const { data } = await axios.get(`/api/restaurant/${type}/tables`)
    dispatch(slice.actions.getTables(data));
};

export const getTablesIni = () => async (dispatch) => {
    dispatch(slice.actions.getTablesIni());
};

export default slice;
