import { authApi } from '../__fakeApi__/authApi';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    blogInitialized: false,
    blogEditionInitialized: false,
    blogLoading: true,
    posts: [],
    edition: [],
};

const slice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        blogRequest(state, action) {
            state.blogLoading = true;
        },
        getBlogs(state, action) {
            state.posts = action.payload.posts;
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.blogLoading = false;
            state.blogInitialized = true;
        },
        getBlog(state, action) {
            state.edition = action.payload
            state.blogEditionInitialized = true
            state.blogLoading = false;
        },
    }
});

export const { reducer } = slice;

export const blogRequest = () => async (dispatch) => {

    dispatch(slice.actions.blogRequest());
};

export const getBlogs = (location) => async (dispatch) => {

    dispatch(slice.actions.blogRequest());

    const { data } = await axios.get(`/api/blog/${location}`)

    dispatch(slice.actions.getBlogs(data));
};


export const getBlog = (slug) => async (dispatch) => {

    dispatch(slice.actions.blogRequest());

    const { data } = await axios.get(`/api/blog_article/${slug}`)

    dispatch(slice.actions.getBlog(data));

    return data
};

export default slice;
