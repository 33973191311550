import { Link, useNavigate } from 'react-router-dom';
import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { useDispatch, useSelector } from '../store';

import Button from '@mui/material/Button';
import { Divider } from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import toast from 'react-hot-toast';
import { userLogout } from '../slices/user';

function CuNav() {
  const { isAuthenticated, userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(userLogout());
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  }

  return (
    <>
      <Nav className="cunav">
        <NavDropdown
          title="Mi Club"
          bsPrefix="super-btn"
          active={true}
          disabled={false}
          id="nav-dropdown1"
          renderMenuOnMount={true}
        >
          <NavDropdown.Item as={Link} to="/miclub/historia">Historia</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/miclub/junta-directiva-y-admision">Junta Directiva y Admisión</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/miclub/reciprocidade">Reciprocidad</NavDropdown.Item>
          {/*<NavDropdown.Item as={Link} to="/miclub/informacion-de-contacto">Información de Contacto</NavDropdown.Item>*/}
          <NavDropdown.Item as={Link} to="/miclub/buzon-de-sugerencias">Buzón de Sugerencias</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/miclub/estatutos-y-pacto-social">Estatutos y Pacto Social</NavDropdown.Item>
        </NavDropdown>

        <NavDropdown title="Restaurantes"
          id="nav-dropdown2"
          active={true}
          disabled={false}
          renderMenuOnMount={true}
        >
          <NavDropdown.Item as={Link} to="/restaurantes/comedor-paitilla">Comedor Paitilla</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/salon-almirante">Salón Almirante</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/barra-almirante">Barra Almirante</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/terraza-miramar">Terraza Miramar</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/snack-bar">Snack Bar</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/time-out">Time Out</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/restaurantes/over-time">Over Time</NavDropdown.Item>
          {/*<NavDropdown.Item as={Link} to="/restaurantes/las-arcadas">Las Arcadas</NavDropdown.Item>*/}
        </NavDropdown>

        <NavDropdown title="Deportes"
          id="nav-dropdown3"
          renderMenuOnMount={true}
        >
          <NavDropdown.Item as={Link} to="/deportes/areas-deportivas">Áreas Deportivas</NavDropdown.Item>
          {/*<NavDropdown.Item as={Link} to="/deportes/clases-deportivas">Clases Deportivas</NavDropdown.Item>*/}
          {/*<NavDropdown.Item as={Link} to="/deportes/kids-club">Kids Club Unión</NavDropdown.Item>*/}
        </NavDropdown>

        {/*
        <NavDropdown title="Concesionarios"
          id="nav-dropdown4"
          renderMenuOnMount={true}
        >
          <NavDropdown.Item as={Link} to="/concesionarios/salon-de-belleza">Salón de belleza</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/concesionarios/barberia">Barbería</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/concesionarios/spa">Spa</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/concesionarios/car-wash">Car Wash</NavDropdown.Item>
        </NavDropdown>
        */}

        <NavItem>
          <Nav.Link as={Link} to="/banquetes">Banquetes</Nav.Link>
        </NavItem>

        <NavItem>
          <Nav.Link as={Link} to="/revista-elite">Revista Élite</Nav.Link>
        </NavItem>

        <NavItem>
          <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
        </NavItem>

        <NavItem>
          <Nav.Link as={Link} to="/actividades">Actividades</Nav.Link>
        </NavItem>

        <NavItem>
          <Nav.Link as={Link} to="/contacto">Contacto</Nav.Link>
        </NavItem>

        {isAuthenticated ? (
          <NavDropdown className='cunav-user-icon' title={
            <span>Mi Cuenta (Hola {userInfo.first_name}!)<PersonIcon /></span>
          }
            id="nav-dropdown5"
            renderMenuOnMount={true}
          >
            {userInfo.role === "Socio" ? (
              <>
              <NavDropdown.Item as={Link} to="/cuenta">Reservas</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cuenta/perfil">Perfil</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cuenta/security">Seguridad</NavDropdown.Item>
              </>
            ) : userInfo.role === "Admin" ? (
              <NavDropdown.Item as={Link} to="/cuenta">Reservas</NavDropdown.Item>
            ) : userInfo.role === "SuperAdmin" ? (
              <>
              <NavDropdown.Item as={Link} to="/cuenta">Reservas</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cuenta/perfil">Perfil</NavDropdown.Item>
              </>
            ) : userInfo.role === "Viewer" ? (
              <NavDropdown.Item as={Link} to="/viewer/reservas">Reservas</NavDropdown.Item>
            ) : userInfo.role === "Counter" ? (
              <>
              <NavDropdown.Item as={Link} to="/counter/reservas-deportivas">Reservas Deportivas</NavDropdown.Item>
              </>
            ) : null}

            {userInfo.role === "Admin" || userInfo.role === "SuperAdmin" ? (
              <NavDropdown.Item as={Link} to="/dashboard">Dashboard</NavDropdown.Item>
            ) : null}


            <NavDropdown.Item href="#" onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
          </NavDropdown>
        ) : (
          <NavDropdown className='cunav-user-icon' title={
            <span>Ingresar <PersonIcon /></span>
          }
            id="nav-dropdown5"
            renderMenuOnMount={true}
          >
            <NavDropdown.Item as={Link} to="/authentication/login">Inicia Sesión</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/authentication/register">Registrarme</NavDropdown.Item>
          </NavDropdown>
        )}
      </Nav>
      {isAuthenticated ? (
        <>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          <Button component={Link} to="/deportes/areas-deportivas" style={{ textDecoration: 'none' }} variant="contained">
            Reservas
          </Button>
        </>
      ) : (
        <>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          <Button component={Link} to="/authentication/register" style={{ textDecoration: 'none' }} variant="contained">
            Registrarme
          </Button>
        </>
      )}
    </>
  )
}

export default CuNav;