import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        users: [],
        user_selected: [],
        analytics: {
            countReservations: {
                initialize: false,
                series: [],
                dataPoints: [],
            },
            totalReservations: {
                initialize: false,
                colors: [],
                labels: [],
                series: [],
            },
            userRegisterPerDay: {
                initialize: false,
                data: []
            },
            UserAgesRangesCount: {
                initialize: false,
                data: [],
                categories: []
            },
            stats: {
                initialize: false,
                active_suspended: 0,
                cancel_suspensions: 0,
                cancelations: 0,
                defaulters: 0,
                noshow: 0,
                noshow_suspensions: 0,
                pending: 0,
                rejected: 0,
                reservations: 0,
                total_suspensions: 0,
                users: 0,
                approved: 0,
                bloqueos: 0,
                reservation_approved: 0
            }
        },
        suspensions: [],
        reservations: [],
        reservation: null,
        reservation_events: [],
        reservations_event: null,
        tables: {
            sports: {
                limit: 20,
                tab: 'past'
            },
            users: {
                limit: 20,
                tab: 'all'
            },
            admins: {
                limit: 10,
                tab: 'all'
            }
        }
    },
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getAllUsers(state, action) {
            state.data.users = action.payload
        },
        getSelectUser(state, action) {
            state.data.user_selected = action.payload
        },
        removeSelectUser(state, action) {
            state.data.user_selected = []
        },
        getAllSuspensions(state, action) {
            state.data.suspensions = action.payload
        },
        getAllReservations(state, action) {
            state.data.reservations = action.payload
        },
        getEventsList(state, action) {
            state.data.reservation_events = action.payload
        },
        getReservation(state, action) {
            state.data.reservation = action.payload
        },
        cancelReservation(state, action) {
            state.data.reservations = state.data.reservations.map((_res) => {
                if (_res.id === action.payload) {
                    const new_obj = { ..._res, status_id: 4 }
                    return new_obj;
                }
                return _res;
            });
        },
        confirmReservation(state, action) {
            state.data.reservations = state.data.reservations.map((_res) => {
                if (_res.id === action.payload) {
                    const new_obj = { ..._res, confirm: 1 }
                    return new_obj;
                }
                return _res;
            });
        },
        logOutDashboard(state) {
            state.data.users = [];
            state.data.reservations = [];
            state.data.reservation = null;
        },
        suspensionUpdate(state, action) {
            state.data.suspensions = state.data.suspensions.map((suspension) => {

                if (suspension.id === action.payload.id) {
                    const new_obj = { ...suspension, status: action.payload.status }
                    return new_obj;
                }
                return suspension;
            });

        },
        analyticsCountReservations(state, action) {
            let dataPoints = []
            let categories = []

            action.payload.types.forEach((e) => {
                categories.push({ 'name': e.name, 'color': e.color, data: [], })
            })

            action.payload.analitycs.forEach((e) => {

                dataPoints.push(e.data.format_date)

                categories.forEach((cat) => {
                    const found = e.data.count.find(obj => {
                        return obj.name === cat.name;
                    });

                    cat.data.push(found.value)

                })

            })
            state.data.analytics.countReservations.dataPoints = dataPoints
            state.data.analytics.countReservations.series = categories
            state.data.analytics.countReservations.initialize = true
        },
        analyticsTotalReservations(state, action) {
            let colors = []
            let labels = []
            let series = []

            action.payload.forEach((e) => {
                colors.push(e.color)
                labels.push(e.name)
                series.push(e.total)
            })

            state.data.analytics.totalReservations.colors = colors
            state.data.analytics.totalReservations.labels = labels
            state.data.analytics.totalReservations.series = series

            state.data.analytics.totalReservations.initialize = true
        },
        analyticsUserRegisterPerDay(state, action) {
            let data = []

            action.payload.forEach((e) => {
                var date = new Date(e.day).getTime()
                data.push([date, e.total])
            })

            state.data.analytics.userRegisterPerDay.data = data
            state.data.analytics.userRegisterPerDay.initialize = true
        },
        analyticsStats(state, action) {

            action.payload.forEach((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    state.data.analytics.stats[key] = value
                });
            });

            state.data.analytics.stats.initialize = true

        },
        analyticsUserAgesRangesCount(state, action) {
            let data = []
            let categories = []

            action.payload.forEach((obj) => {
                data.push(obj.count)
                categories.push(obj.age_range)
            });

            state.data.analytics.UserAgesRangesCount.data = data
            state.data.analytics.UserAgesRangesCount.categories = categories
            state.data.analytics.UserAgesRangesCount.initialize = true
        }
    }
});

export const { reducer } = slice;

export const generate_keys = async (response) => {
    const myKey = Buffer.from(response.two, 'base64');
    const iv = Buffer.from(response.three, 'base64');
    const ciphertext = Buffer.from(response.one, 'base64');

    const key = await window.crypto.subtle.importKey('raw', myKey, 'AES-CBC', false, ['decrypt']);
    const decryptedData = await window.crypto.subtle.decrypt({ name: 'AES-CBC', iv }, key, ciphertext);

    const decoded = new TextDecoder().decode(decryptedData);
    const decryptedObject = JSON.parse(decoded);

    return decryptedObject
}

export const getAllUsers = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dashboard/randomRequest/',
        config
    );

    const decryptedObject = await generate_keys(data)

    dispatch(slice.actions.getAllUsers(decryptedObject));

    return decryptedObject
};

export const getSelectUser = (token, id) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        `/api/dashboard/getSelectUser/${id}`,
        config
    );

    const decryptedObject = await generate_keys(data)

    dispatch(slice.actions.getSelectUser(decryptedObject));

    return decryptedObject
};

export const removeSelectUser = () => async (dispatch) => {
    dispatch(slice.actions.removeSelectUser());
};

export const getAllSuspensions = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dashboard/getAllSuspensions/',
        config
    );

    dispatch(slice.actions.getAllSuspensions(data));

    return data
};

export const getAllReservations = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dashboard/getAllReservations/',
        config
    );


    const decryptedObject = await generate_keys(data)

    dispatch(slice.actions.getAllReservations(decryptedObject));

    return decryptedObject
};

export const getEventsList = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dashboard/getEventsList/',
        config
    );


    const decryptedObject = await generate_keys(data)

    dispatch(slice.actions.getEventsList(decryptedObject));

    return decryptedObject
};

export const getReservation = (token, idres) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.post(
        '/api/dashboard/getReservation/',
        { 'reservation': idres },
        config
    );

    dispatch(slice.actions.getReservation(data));

    return data
};

export const cancelReservation = (resId) => async (dispatch) => {
    dispatch(slice.actions.cancelReservation(resId));
};

export const confirmReservation = (resId) => async (dispatch) => {
    dispatch(slice.actions.confirmReservation(resId));
};

export const suspensionUpdate = (update) => async (dispatch) => {
    dispatch(slice.actions.suspensionUpdate(update));
};

export const logOutDashboard = () => async (dispatch) => {
    dispatch(slice.actions.logOutDashboard());
};

export const analyticsCountReservations = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dasboard/analitycsCountReservations/',
        config
    );

    dispatch(slice.actions.analyticsCountReservations(data));
};

export const analyticsTotalReservations = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dasboard/analyticsTotalReservations/',
        config
    );

    dispatch(slice.actions.analyticsTotalReservations(data));
};

export const analyticsUserRegisterPerDay = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dasboard/analyticsRegistartionPerDay/',
        config
    );

    dispatch(slice.actions.analyticsUserRegisterPerDay(data));
};

export const analyticsStats = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dasboard/analyticsStats/',
        config
    );

    dispatch(slice.actions.analyticsStats(data));
};

export const analyticsUserAgesRangesCount = (token) => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const { data } = await axios.get(
        '/api/dasboard/analyticsUserAgesRangesCount/',
        config
    );

    dispatch(slice.actions.analyticsUserAgesRangesCount(data));
};

export default slice;

