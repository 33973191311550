import { useEffect } from 'react';

function Botmaker() {

  const dashCheck = () => {
    if (window.location.href.indexOf("dashboard") !== -1) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {

    if (dashCheck() === false) {
      const script = document.createElement("script")

      script.type = 'text/javascript';
      script.async = true;
      script.src = '/static/botmaker.js';

      document.body.insertBefore(script, document.body.childNodes[0]);

      return () => {
        document.body.removeChild(script)
        var wrapper = document.getElementById("botmaker-wrapper");
        if (wrapper !== null) {
          wrapper.remove();
        }

      }
    }

  }, [])

  return null
}

export default Botmaker;