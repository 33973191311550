import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import NotificationCancelation from './NotificationCancelation';
import axios from 'axios';

const ListUsersReservation = ({ users, resid }) => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const [rate, serRate] = useState(0);
    const [rateLoading, serRateLoading] = useState(true);

    const cancelRate = async () => {

        await axios({
            method: "POST",
            url: "/api/reservation/cancelationRate/",
            data: { reservation: resid, user: users[0].user_id },
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        })
            .then(response => {
                serRate(response.data.detail)
                serRateLoading(false)
            })
            .catch(error => {
                serRate(0)
                serRateLoading(false)
                console.log(error)
            });
    }

    useEffect(() => {
        cancelRate()
    }, []);

    return (
        <>
            <Grid container>
                <Grid item sm={6}>
                    <Box display="flex" justifyContent="flex-start">
                    <Typography id="modal-reservation" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Usuario(s)
                    </Typography>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box display="flex" justifyContent="flex-end">

                    </Box>
                </Grid>
            </Grid>
            {users.map((option, index) => (
                <List key={index}>
                    <ListItem sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar alt={option.name} src={option.avatar !== "" ? "static/media/" + option.avatar : ""} />
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography
                                sx={{ display: 'inline', textTransform: 'uppercase', fontWeight: 700, fontSize: 12, lineHeight: 'normal' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {index + 1}. {option.name}
                            </Typography>
                            {index === 0 && (
                                <>

                                    <Typography
                                        sx={{ display: 'block', fontWeight: 500, fontSize: 12, lineHeight: 'normal' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >

                                        {"Tasa de cancelación: "} {rateLoading === true ? <CircularProgress size={10} /> : rate + "%"}
                                    </Typography>
                                </>
                            )}
                        </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{ marginLeft: '56px' }} />
                </List>
            ))}
            <NotificationCancelation resid={resid} />
        </>
    )
}

export default ListUsersReservation;