import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('img')``;

const LogoWhite = (props) => (
  <LogoRoot
    height="80"
    width="auto"
    src='/static/logos/clubunion-white.svg'
    {...props}
  >
  </LogoRoot>
);

export default LogoWhite;
