import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventInitialized: false,
  eventsInitialized: false,
  reservedInitialized: false,
  reservedDetailsInitialized: false,
  eventLoading: true,
  totalReserved: null,
  seatsReserved: [],
  event: [],
  events: [],
  activeReservation: false,
  reservedDetails: [],
};

const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    getEvents(state, action) {},
    getEvent(state, action) {
      if (action.payload.length === 1) {
        const data = action.payload[0];
        state.event = data;
        state.eventInitialized = true;
      }
    },
    getEventCount(state, action) {
      state.totalReserved = action.payload.count[0].reserved_tables + "/" + action.payload.count[0].total_tables;
      state.seatsReserved = action.payload.tables;
      state.activeReservation = action.payload.tables.some((item) => item.is_current_user === 1);
      state.reservedInitialized = true;
      
    },
    resetEvent(state) {
      state.eventInitialized = false;
      state.reservedInitialized = false;
      state.eventLoading = false;
      state.totalReserved = null;
      state.seatsReserved = [];
      state.event = [];
    },
    updateActiveReservation(state) {
      state.activeReservation = true
    },
    getEventReservedDetails(state, action) {
      state.reservedDetails = action.payload;
      state.reservedDetailsInitialized = true
    }
  },
});

export const { reducer } = slice;

export const getEvents = (location) => async (dispatch) => {
  const { data } = await axios.get(`/api/blog/${location}`);

  dispatch(slice.actions.getBlogs(data));
};

export const getEvent = (slug, prefix, token) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(`/api/event/${prefix}/${slug}`, config);

  dispatch(slice.actions.getEvent(data));

  return data;
};

export const getEventCount = (slug, prefix, token) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, status } = await axios.get(`/api/event/${prefix}/${slug}/count/`, config);

  if (status === 200) {
    dispatch(slice.actions.getEventCount(data));
  }
};

export const resetEvent = () => (dispatch) => {
  dispatch(slice.actions.resetEvent());
};

export const updateActiveReservation = () => (dispatch) => {
  dispatch(slice.actions.updateActiveReservation());
};

export const getEventReservedDetails = (slug, prefix, token) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, status } = await axios.get(`/api/event/${prefix}/${slug}/details/`, config);

  if (status === 200) {
    dispatch(slice.actions.getEventReservedDetails(data));
  }
};


export default slice;
