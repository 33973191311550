import { Box, Divider, Drawer } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";

import InstallPWA from "./InstallPWA";
import Logo from "./Logo";
import NavSection from "./NavSection";
import PropTypes from "prop-types";
import Scrollbar from "./Scrollbar";
import { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "../store";

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const { isAuthenticated, userInfo } = useSelector((state) => state.userLogin);

  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const perfilMenu = () => {
    const menu = [];
    if (isAuthenticated) {
      if (userInfo.role === "Admin" || userInfo.role === "Socio" || userInfo.role === "SuperAdmin") {
        menu.push({
          title: `Mi Cuenta (Hola ${userInfo.first_name}!)`,
          path: "/cuenta",
          admin: 0,
        });
      }

      if (userInfo.role === "Viewer") {
        menu.push({
          title: "Reservas",
          path: "/viewer/reservas",
          admin: 0,
        });
      }

      if (userInfo.role === "Counter") {
        menu.push({
          title: "Counter",
          path: "/counter",
          admin: 0,
        });
        menu.push(
          {
            title: "Reservas Deportivas",
            path: "/counter/reservas-deportivas",
            admin: 0,
          },/*
          {
            title: "Reservas Eventos",
            path: "/counter/reservas-eventos",
            admin: 0,
          }*/
        );
      }

      menu.push({
        title: "Cerrar sesión",
        path: "/authentication/logout",
        admin: 0,
      });

      if (userInfo.role === "Socio") {
        menu.push({
          title: "Contacto",
          path: "/contacto",
          admin: 0,
        });
      }

    } else {
      menu.push(
        {
          title: "Iniciar sesión",
          path: "/authentication/login",
          admin: 0,
        },
        {
          title: "Registrarme",
          path: "/authentication/register",
          admin: 0,
        },
        {
          title: "Contacto",
          path: "/contacto",
          admin: 0,
        }
      );
    }
    return menu;
  };

  const sections = [];

  if (isAuthenticated) {
    if (userInfo.role === "SuperAdmin" || userInfo.role === "Admin") {
      sections.push({
        title: "Administración",
        items: [
          {
            title: "Dashboard",
            path: "/dashboard",
            admin: 0,
            children: [
              {
                title: "Reservaciones Deportivas",
                path: "/dashboard/reservaciones/deportes",
                admin: 0,
              },
              {
                title: "Socios",
                path: "/dashboard/usuarios/socios",
                admin: 0,
              },
              {
                title: "Suspensiones",
                path: "/dashboard/usuarios/suspensiones",
                admin: 0,
              },
            ],
          },
        ],
      });
    }
  }

  sections.push(
    {
      title: "Perfil",
      items: perfilMenu(),
    },
    {
      title: "General",
      admin: 0,
      items: [
        {
          title: "Mi Club",
          path: "/miclub/historia",
          admin: 0,
          children: [
            {
              title: "Historia",
              path: "/miclub/historia",
              admin: 0,
            },
            {
              title: "Junta Directiva y Admisión",
              path: "/miclub/junta-directiva-y-admision",
              admin: 0,
            },
            {
              title: "Reciprocidad",
              path: "/miclub/reciprocidade",
              admin: 0,
            },
            {
              title: "Buzón de Sugerencias",
              path: "/miclub/buzon-de-sugerencias",
              admin: 0,
            },
            {
              title: "Estatutos y Pacto Social",
              path: "/miclub/estatutos-y-pacto-social",
              admin: 0,
            },
          ],
        },
        {
          title: "Restaurantes",
          admin: 0,
          path: "/dashboard/customers",
          children: [
            {
              title: "Comedor Paitilla",
              path: "/restaurantes/comedor-paitilla",
              admin: 0,
            },
            {
              title: "Salón Almirante",
              path: "/restaurantes/salon-almirante",
              admin: 0,
            },
            {
              title: "Barra Almirante",
              path: "/restaurantes/barra-almirante",
              admin: 0,
            },
            {
              title: 'Terraza Miramar',
              path: '/restaurantes/terraza-miramar'
            },
            {
              title: "Snack Bar",
              path: "/restaurantes/snack-bar",
              admin: 0,
            },
            {
              title: "Time Out",
              path: "/restaurantes/time-out",
              admin: 0,
            },
            {
              title: "Over Time",
              path: "/restaurantes/over-time",
              admin: 0,
            } /*
            {
              title: 'Las Arcadas',
              path: '/restaurantes/las-arcadas'
            }*/,
          ],
        },
        {
          title: "Áreas Deportivas",
          admin: 0,
          path: "/deportes/areas-deportivas",
          children: [
            {
              title: "Cancha de Pádel",
              path: "/deportes/areas-deportivas/cancha-de-paddle",
              admin: 0,
            },
            {
              title: "Canchas de Tenis",
              path: "/deportes/areas-deportivas/cancha-de-tenis",
              admin: 0,
            },
            {
              title: "Cancha de Pickleball",
              path: "/deportes/areas-deportivas/cancha-de-pickleball",
              admin: 0,
            },
            {
              title: "Cancha de Squash",
              path: "/deportes/areas-deportivas/cancha-de-squash",
              admin: 0,
            },
            {
              title: "Piscina",
              path: "/deportes/areas-deportivas/piscina",
              admin: 0,
            },
            {
              title: "Cancha de Baloncesto",
              path: "/deportes/areas-deportivas/cancha-de-baloncesto",
              admin: 0,
            },
            {
              title: "Cancha Multiuso",
              path: "/deportes/areas-deportivas/cancha-multiuso",
              admin: 0,
            },
            {
              title: "Golf Lab",
              path: "/deportes/areas-deportivas/golf-lab",
              admin: 0,
            },
            {
              title: "Gimnasio",
              path: "/deportes/areas-deportivas/gimnasio",
              admin: 0,
            },
          ],
        },
        {
          title: "Banquetes",
          path: "/banquetes",
          admin: 0,
        },
        {
          title: "Revista Élite",
          path: "/revista-elite",
          admin: 0,
        },
        {
          title: "Blog",
          path: "/blog",
          admin: 0,
        },
        {
          title: "Actividades",
          path: "/actividades",
          admin: 0,
        },
        {
          title: "Términos y Condiciones",
          path: "/terminos-y-condiciones",
          admin: 0,
        },
      ],
    }
  );

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          width: 256,
        },
      }}
    >
      {content}
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default MainSidebar;
