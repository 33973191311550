import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core/styles";

function Approval() {
  let navigate = useNavigate();

  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showCommunique, setShowCommunique] = useState(false);
  const currentDate = new Date();
  const targetDate = new Date("2024-04-15T23:59:59");

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    custonButton: {
      padding: "0px 8px",
      fontSize: "13px",
      fontWeight: 500,
      marginLeft: 10,
      backgroundColor: "#f49c1a"
    },
  }));

  const classes = useStyles();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `https://club-union.com/static/media/semana-santa-horarios-2023.jpeg`;
    link.href = `https://club-union.com/static/media/semana-santa-horarios-2023.jpeg`;
    link.click();
  };

  function handleClick(path) {
    navigate(path);
  }
  
  useEffect(() => {

    if (currentDate.getTime() < targetDate.getTime()) {
      setShowCommunique(true);
    } else {
      setShowCommunique(false);
    }

    if (location.pathname === "/contest/burger-challenge-abril-2024") {
      setOpen(false)
    }
    
  }, []);

  return (
    <>
      {showCommunique && (
        <>
          <Collapse
            in={open}
            sx={{ width: "100%", p: open === false ? "0px !important" : "" }}
            className="communique"
          >
            ¡Vota por la mejor hamburguesa en nuestro Burger Challenge!🍔
            <Button
              size="small"
              variant="contained"
              onClick={() => handleClick("/contest/burger-challenge-abril-2024")}
              className={classes.custonButton}
            >
              Votar
            </Button>
            <IconButton
              aria-label="close"
              size="small"
              sx={{ color: '#ffffff' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Collapse>

          {/*<Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleDialogClose}
                sx={{ position: "absolute", top: 8, right: 15 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="https://club-union.com/static/media/semana-santa-horarios-2023.jpeg"
                    alt="Imagen"
                    style={{ maxWidth: "100%" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onDownload}
                    style={{ marginTop: "16px" }}
                  >
                    Descargar
                  </Button>
                </div>
              </DialogContentText>
            </DialogContent>
            </Dialog>*/}
        </>
      )}
    </>
  );
}

export default Approval;
