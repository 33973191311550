import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Botmaker from './Botmaker';
import Fab from '@mui/material/Fab';
import Footer from './Footer';
import { IdleTimer } from './IdleTimer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import NavBottom from './NavBottom';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Outlet } from 'react-router-dom';
import PWAPrompt from 'react-ios-pwa-prompt';
import PropTypes from 'prop-types';
import ScrollToTop from "react-scroll-up";
import TimeoutModal from './TimeoutModal';
import { experimentalStyled } from '@material-ui/core/styles';
import { useIdleTimer } from 'react-idle-timer';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

const backToTopStyle = {
  position: 'fixed',
  bottom: "10%",
  right: "5%",
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s'
}

const MainLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <MainLayoutRoot>
      <MainNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <MainSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      {children || <Outlet />}
      <Footer />
      {/*<ScrollToTop showUnder={160} style={backToTopStyle} className="backtotop">
        <Fab size="medium" color="primary" aria-label="add">
          <NavigationIcon />
        </Fab>
      </ScrollToTop>*/}
      {/*<PWAPrompt />*/}
      {/*<NavBottom />*/}
      <Botmaker />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
