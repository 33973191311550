import 'moment-timezone';

import moment from 'moment';

const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return moment(result).tz('America/Panama').format();
};

export default addDays;