import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Interweave from "interweave";
import axios from "axios";

export default function Terminosycondiciones() {
  const [terms, setTerms] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const { data } = await axios.get("/api/getTerms/");
        setTerms(data.terms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching terms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTerms();
  }, []);

  return loading ? (
    <Box sx={{ display: "flex", height: "100%" }} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <Interweave content={terms} />
  );
}