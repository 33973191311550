import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { approvalCheck } from '../slices/user';

function Approval() {
    const [open, setOpen] = useState(true);

    const { approval, isAuthenticated, userInfo } = useSelector((state) => state.userLogin);
    const dispatch = useDispatch()

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(approvalCheck(userInfo.token))
        }
    }, []);

    return (
        <>
            {isAuthenticated && approval.approved == 1 && (
                <Collapse in={open} sx={{ width: '100%', p: open === false ? '0px !important' : '' }} className="defaulter">
                    Estimado Socio, Su usuario está pendiente de aprobación.
                    <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Collapse>
            )}
        </>
    )
}

export default Approval;