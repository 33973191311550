import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('img')``;

const Logo = (props) => (
  <LogoRoot
    height="50"
    width="auto"
    src='/static/logos/clubunion.svg'
    {...props}
  >
  </LogoRoot>
);

export default Logo;
